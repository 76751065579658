import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import './css/appointments.css'; // Existing CSS
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitizing HTML
import { parseISO, format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
/* If you use CSS */
import 'react-pro-sidebar/dist/css/styles.css'; // Ensure you're using react-pro-sidebar@0.7.1
import '@fortawesome/fontawesome-free/css/all.min.css';

import UserContext from './context/UserContext';
import PartsContext from './context/PartsContext';
import Loading from './Loading';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate } from 'react-router-dom'; // Import Link for breadcrumbs and useNavigate
import { Table, Card, Row, Col } from 'react-bootstrap'; // Import Card, Row, Col for layout
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar'; // Updated import for version 0.7.1
import {
  FaRegStickyNote,
  FaCamera,
  FaTools,
  FaTruck, // Import the truck icon
  FaBars, // Import the bars icon for the toggle button
} from 'react-icons/fa'; // Ensure react-icons is installed
import SignatureCanvas from 'react-signature-canvas'; // Import SignatureCanvas
import Form from 'react-bootstrap/Form'; // Added import for Form component
import SignaturePad from 'signature_pad';
import { sendEmail } from '../utils/email';

import imageCompression from 'browser-image-compression';
import AWS from 'aws-sdk';

// ***** IMPORT YOUR NEW APPOINTMENT NOTES COMPONENT *****
import AppointmentNotes from './AppointmentNotes';

// NEW: Import the new reusable ImageGallery component
import ImageGallery from './ImageGallery';

const STATUSES = ['Dispatched', 'Depart', 'Arrive', 'Complete'];

export default function AppointmentWorkFlow() {
  // **Move all useState declarations to the top**
  const { user } = useContext(UserContext);
  const { parts, setParts } = useContext(PartsContext);
  const SPECIAL_PART_NUMBERS = ['3272', '115', '3292', '3288', '6155'];
  const [currentStatus, setCurrentStatus] = useState(STATUSES[0]);

  const [signature, setSignature] = useState(null);
  const sigCanvas = useRef({});
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const [activeSection, setActiveSection] = useState('status');

  // ----------------------
  // Customer (Permanent) Notes State
  // ----------------------
  const [customerNotes, setCustomerNotes] = useState('Loading customer notes...');
  const [customerNotesLoading, setCustomerNotesLoading] = useState(false);
  const [customerNotesError, setCustomerNotesError] = useState(null);

  // State variables for fetched appointments, service orders, loading, and error handling
  const [appointments, setAppointments] = useState([]);
  const [serviceOrders, setServiceOrders] = useState([]);
  const [appointmentsLoading, setAppointmentsLoading] = useState(false);
  const [serviceOrdersLoading, setServiceOrdersLoading] = useState(false);
  const [error, setError] = useState(null);

  // State variables for parts modal
  const [showPartsModal, setShowPartsModal] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedPart, setSelectedPart] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [price, setPrice] = useState('');
  const [cost, setCost] = useState('');
  const [partsLoading, setPartsLoading] = useState(false);
  const [partsError, setPartsError] = useState(null);

  // Breadcrumbs State
  const breadcrumbItems = [
    { name: 'Appointments', link: '/appointments' },
    {
      name: selectedAppointment
        ? `Appointment #${selectedAppointment.AppointmentNumber}`
        : 'Details',
    },
  ];

  // API details (Replace with environment variables later)
  const GET_APPOINTMENTS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_appointments';
  const GET_SERVICE_ORDERS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_acu_specific_so';
  const UPDATE_APPOINTMENT_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_appointment';
  const GET_ALL_ITEMS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_all_items';
  const ADD_ITEM_TO_ACUMATICA_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_add_item_to_acu_SO'; // Corrected API Endpoint

  // *** RESTORED for capturing SIGNATURE only ***
  const ADD_IMAGES_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_add_items_acu_so';

  const GET_THUMBNAILS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_so_images'; // Thumbnails Fetch Endpoint
  const GET_CUSTOMER_DETAILS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_customer_details';
  const API_KEY = process.env.REACT_APP_CONEXCS_API_KEY; // Replace with your actual API key or use environment variables

  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  // Filter parts based on the search term, checking both itemID and itemDescription
  const filteredParts = parts.filter(
    (part) =>
      part.itemID.toLowerCase().includes(searchTerm.toLowerCase()) ||
      part.itemDescription.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // State variables for detail items
  const [detailItems, setDetailItems] = useState([]);
  const [detailItemsLoading, setDetailItemsLoading] = useState(false);
  const [detailItemsError, setDetailItemsError] = useState(null);

  // New Endpoint for Detail Items
  const GET_DETAIL_ITEMS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_detail_items_for_SO';

  // Inside the AppointmentWorkFlow component
  const [galleryRefreshCounter, setGalleryRefreshCounter] = useState(0);

  /**
   * Fetch detail items for a given service order number
   * @param {string} serviceOrderNumber - The service order number (e.g., "FS000002")
   */
  const fetchDetailItems = async (serviceOrderNumber) => {
    setDetailItemsLoading(true);
    setDetailItemsError(null);
    console.log('Starting fetchDetailItems...');

    try {
      const response = await fetch(GET_DETAIL_ITEMS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY, // Reusing the existing API key
        },
        body: JSON.stringify({ serviceOrderNumber }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Detail Items:', data);

      if (Array.isArray(data)) {
        // Assuming the response is an array with one object containing 'details'
        const detailData = data[0]?.details || [];
        setDetailItems(detailData);
      } else {
        console.warn('No detail items found in the response.');
        setDetailItems([]);
      }
    } catch (err) {
      console.error('Error fetching detail items:', err);
      setDetailItemsError(
        'Failed to load detail items. Please try again later.'
      );
    } finally {
      setDetailItemsLoading(false);
      console.log('fetchDetailItems completed.');
    }
  };

  /**
   * Trigger fetchDetailItems when a new appointment is selected
   */
  useEffect(() => {
    if (selectedAppointment) {
      const serviceOrderNumber = selectedAppointment.ServiceOrderID;
      if (serviceOrderNumber) {
        fetchDetailItems(serviceOrderNumber);
      } else {
        setDetailItems([]);
      }
    } else {
      setDetailItems([]);
    }
  }, [selectedAppointment]);

  /**
   * Define the missing functions
   */
  const handleNextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBackStep = () => {
    setCurrentStep((prevStep) => (prevStep > 1 ? prevStep - 1 : prevStep));
  };

  /**
   * Fetch appointments from the Lambda function using POST request
   * @param {string} TechnicianEmail - The email of the logged-in user
   */
  const fetchAppointments = async (TechnicianEmail) => {
    setAppointmentsLoading(true);
    setError(null);
    console.log('Starting fetchAppointments...');

    try {
      const response = await fetch(GET_APPOINTMENTS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY, // Include the API key in headers
        },
        body: JSON.stringify({
          TechnicianEmail,
          Statuses: ['Dispatched', 'Depart', 'Arrive'],
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Appointments:', data); // Log the raw appointments data

      if (data.data) {
        // Log each appointment's VisitID
        data.data.forEach((appointment, index) => {
          console.log(`Appointment ${index + 1} VisitID:`, appointment.VisitID);
        });

        // **Sort the appointments array**
        let sortedAppointments = data.data.sort((a, b) => {
          // Check if either appointment is 'On Hold'
          const aOnHold = a.Status === 'On Hold' || a.OnHold === 1 || a.OnHold === true;
          const bOnHold = b.Status === 'On Hold' || b.OnHold === 1 || b.OnHold === true;

          // If one of them is 'On Hold', sort it to the bottom
          if (aOnHold && !bOnHold) return 1;
          if (!aOnHold && bOnHold) return -1;

          // If both have the same 'On Hold' status, sort by concatenated appointment numbers
          const aConcatenated = (a.ServiceOrderID + a.AppointmentNumber).replace(/\D/g, '');
          const bConcatenated = (b.ServiceOrderID + b.AppointmentNumber).replace(/\D/g, '');

          return parseInt(aConcatenated, 10) - parseInt(bConcatenated, 10);
        });

        setAppointments(sortedAppointments);
      } else {
        console.warn('No appointments found in the response.');
        setAppointments([]);
      }
    } catch (err) {
      console.error('Error fetching appointments:', err);
      setError('Failed to load appointments. Please try again later.');
    } finally {
      setAppointmentsLoading(false);
      console.log('fetchAppointments completed.');
    }
  };

  /**
   * Fetch service orders based on appointments
   * @param {Array} serviceOrderIDs - Array of ServiceOrderID from appointments
   */
  const fetchServiceOrders = async (serviceOrderIDs) => {
    setServiceOrdersLoading(true);
    setError(null);
    console.log('Starting fetchServiceOrders...');

    try {
      const response = await fetch(GET_SERVICE_ORDERS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY, // Include the API key in headers
        },
        body: JSON.stringify({ serviceOrderIDs }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Service Orders:', data); // Log the raw service orders data

      // Parse the response body
      let serviceOrdersArray = [];

      if (data.body) {
        serviceOrdersArray = JSON.parse(data.body);
        console.log('Parsed Service Orders Body:', serviceOrdersArray); // Log parsed body
      } else if (data.data) {
        // In case Lambda returns data directly
        serviceOrdersArray = data.data;
        console.log('Parsed Service Orders Data:', serviceOrdersArray); // Log parsed data
      } else {
        // Handle unexpected response format
        throw new Error('Unexpected API response format');
      }

      if (Array.isArray(serviceOrdersArray) && serviceOrdersArray.length > 0) {
        setServiceOrders(serviceOrdersArray);

        // Optionally, log each service order's VisitID
        serviceOrdersArray.forEach((so, index) => {
          console.log(`Service Order ${index + 1} VisitID:`, so.VisitID);
        });
      } else {
        setServiceOrders([]);
        setError('No service orders found for the fetched appointments.');
      }
    } catch (err) {
      console.error('Error fetching service orders:', err);
      setError('Failed to load service orders. Please try again later.');
    } finally {
      setServiceOrdersLoading(false);
      console.log('fetchServiceOrders completed.');
    }
  };

  /**
   * Fetch parts from the Lambda function
   */
  const fetchParts = async () => {
    setPartsLoading(true);
    setPartsError(null);
    console.log('Starting fetchParts...');

    try {
      const response = await fetch(GET_ALL_ITEMS_ENDPOINT, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Parts:', data);

      if (Array.isArray(data)) {
        setParts(data);
      } else if (data.body) {
        const parsedData = JSON.parse(data.body);
        setParts(parsedData);
      } else {
        throw new Error('Unexpected API response format');
      }
    } catch (err) {
      console.error('Error fetching parts:', err);
      setPartsError('Failed to load parts. Please try again later.');
    } finally {
      setPartsLoading(false);
      console.log('fetchParts completed.');
    }
  };

  /**
   * Fetch customer details, including Customer Notes
   */
  const fetchCustomerDetails = async () => {
    if (!selectedAppointment) {
      console.warn('No appointment selected. Cannot fetch customer details.');
      return;
    }

    // Find the corresponding service order
    const serviceOrder = serviceOrders.find(
      (so) => so.serviceOrderNumber === selectedAppointment.ServiceOrderID
    );

    if (!serviceOrder) {
      console.warn('No service order found for the selected appointment.');
      setCustomerNotes('No customer notes available.');
      return;
    }

    const customerName = serviceOrder.customerName;

    setCustomerNotesLoading(true);
    setCustomerNotesError(null);

    try {
      const response = await fetch(GET_CUSTOMER_DETAILS_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({
          customerName, // Pass customerName as required by the API
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Customer Details:', data);

      if (
        data.customerDetails &&
        Array.isArray(data.customerDetails) &&
        data.customerDetails.length > 0
      ) {
        const customerNote =
          data.customerDetails[0]?.note?.value || 'No customer notes available.';
        setCustomerNotes(customerNote);
      } else {
        console.warn('No customer details found in the response.');
        setCustomerNotes('No customer notes available.');
      }
    } catch (err) {
      console.error('Error fetching customer details:', err);
      setCustomerNotesError(
        'Failed to load customer notes. Please try again later.'
      );
      setCustomerNotes('Error loading customer notes.');
    } finally {
      setCustomerNotesLoading(false);
      console.log('fetchCustomerDetails completed.');
    }
  };

  /**
   * Trigger fetchServiceOrders when appointments are fetched
   */
  useEffect(() => {
    if (appointments.length > 0) {
      const serviceOrderIDs = [
        ...new Set(
          appointments.map((appt) => appt.ServiceOrderID.replace('FS', ''))
        ),
      ];
      console.log('Fetching service orders for IDs:', serviceOrderIDs);
      (async () => {
        await fetchServiceOrders(serviceOrderIDs);
      })();
    }
  }, [appointments]);

  /**
   * Fetch parts when the modal is opened and parts list is empty
   */
  useEffect(() => {
    if (showPartsModal && parts.length === 0) {
      fetchParts();
    }
  }, [showPartsModal]);

  /**
   * Fetch appointments when the component mounts
   */
  useEffect(() => {
    if (user && user.email) {
      console.log('User is logged in. Initiating appointment fetch...');
      (async () => {
        await fetchAppointments(user.email);
      })();
    } else {
      console.warn('User is not logged in or email is missing.');
    }
  }, [user]);

  /**
   * Fetch customer details when the Notes section is active and an appointment is selected
   */
  useEffect(() => {
    if (activeSection === 'notes' && selectedAppointment) {
      fetchCustomerDetails();
    }
  }, [activeSection, selectedAppointment]);

  /**
   * Handle selecting an appointment
   */
  const handleSelectAppointment = (appointment) => {
    setSelectedAppointment(appointment);
    setCurrentStatus(appointment.Status);
  };

  /**
   * Handle returning to the appointments list
   */
  const navigate = useNavigate(); // Initialize useNavigate
  const handleBackToList = () => {
    setSelectedAppointment(null); // Clear the selected appointment
    navigate('/AppointmentWorkFlow'); // Navigate back to the list
  };

  /**
   * Format a date string into MM/DD/YYYY format
   */
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const dateObj = new Date(dateString);
    if (isNaN(dateObj)) return 'Invalid Date';
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
  };

  /**
   * Get the next status in the flow
   */
  const getNextStatus = (currentStatus) => {
    const currentIndex = STATUSES.indexOf(currentStatus);
    if (currentIndex === -1 || currentIndex === STATUSES.length - 1) return null;
    return STATUSES[currentIndex + 1];
  };

  // -------------------------------
  // SIGNATURE CAPTURE FOR COMPLETE
  // -------------------------------
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const signatureCanvasRef = useRef(null);
  const [uploadingSignature, setUploadingSignature] = useState(false);
  const [signatureError, setSignatureError] = useState(null);

  // We'll store a "pending status" so we know if we were trying to set "Complete"
  // after the signature is obtained
  const [pendingComplete, setPendingComplete] = useState(false);

  // Old readFileAsDataURL helper (used by handleSubmitSignature)
  const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  const handleOpenSignatureModal = () => {
    setSignatureError(null);
    setShowSignatureModal(true);
  };

  const handleCloseSignatureModal = () => {
    setShowSignatureModal(false);
    clearModalSignature();
  };

  const clearModalSignature = () => {
    if (signatureCanvasRef.current) {
      signatureCanvasRef.current.clear();
    }
    setSignature(null);
  };

  /**
   * Upload the captured signature to the old ADD_IMAGES_ENDPOINT
   * Then finalize the appointment as "Complete"
   */
  const handleSaveSignature = async () => {
    if (!signatureCanvasRef.current) return;

    if (signatureCanvasRef.current.isEmpty()) {
      setSignatureError('Please provide a signature before saving.');
      return;
    }

    // Convert canvas to base64
    const signatureDataURL = signatureCanvasRef.current
      .getTrimmedCanvas()
      .toDataURL('image/png');

    if (!selectedAppointment || !selectedAppointment.ServiceOrderID) {
      setSignatureError('No appointment selected or invalid ServiceOrderID.');
      return;
    }

    setUploadingSignature(true);
    setSignatureError(null);

    try {
      // Prepare the signature as base64
      const signatureBase64 = signatureDataURL.split(',')[1];

      // We only have a single "file" here (the signature).
      // Use the old add_images_endpoint payload:
      const filesData = [
        {
          filename: `signature-${selectedAppointment.ServiceOrderID}-${selectedAppointment.AppointmentNumber}.png`,
          fileData: signatureBase64,
        },
      ];

      const payload = {
        serviceOrderNumber: selectedAppointment.ServiceOrderID,
        files: filesData,
      };

      // Upload signature to the old endpoint
      const response = await fetch(ADD_IMAGES_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`API responded with status ${response.status}: ${errorText}`);
      }

      // If successful, we can proceed to finalize the status to "Complete"
      console.log('Signature uploaded successfully via old endpoint.');

      // Now that signature is captured and uploaded, finalize the status update
      await doCompleteAfterSignature();

      // Close modal
      setShowSignatureModal(false);
      clearModalSignature();
    } catch (error) {
      console.error('Error uploading signature:', error);
      setSignatureError('Failed to upload signature. Please try again.');
    } finally {
      setUploadingSignature(false);
    }
  };

  /**
   * The actual "Complete" logic that used to reside in handleStatusChange
   * We call this AFTER the signature is uploaded successfully
   */
  const doCompleteAfterSignature = async () => {
    // Payload for updating the appointment status
    const payload = {
      ServiceOrderID: selectedAppointment.ServiceOrderID,
      AppointmentNumber: selectedAppointment.AppointmentNumber,
      Status: 'Complete',
    };

    try {
      // 1) Update Appointment in Acumatica
      const response = await fetch(UPDATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Appointment updated to COMPLETE successfully:', data);

      // Update the selected appointment's status in state
      setSelectedAppointment((prev) => ({
        ...prev,
        Status: 'Complete',
      }));

      // Update the currentStatus state to reflect the new status
      setCurrentStatus('Complete');

      // Update the appointments list to reflect the new status
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === selectedAppointment.AppointmentNumber
            ? { ...appt, Status: 'Complete' }
            : appt
        )
      );

      // 2) Send completion email
      const concatenatedAppointmentNumber = `${selectedAppointment.ServiceOrderID}-${selectedAppointment.AppointmentNumber}`;
      const serviceOrder = serviceOrders.find(
        (so) => so.serviceOrderNumber === selectedAppointment.ServiceOrderID
      );
      const customerName = serviceOrder?.customerName || 'N/A';
      const description = serviceOrder?.description || 'N/A';
      const subject = `${concatenatedAppointmentNumber} has been completed by ${user.email}.`;
      const body = `${subject}\n\nCustomer Name: ${customerName}\nDescription: ${description}`;

      try {
        await sendEmail({
          toAddress: 'dispatchgroup@collins-cs.com',
          subject,
          body,
          replyTo: user.email,
        });
        console.log('Completion email sent successfully.');
      } catch (emailError) {
        console.error('Error sending completion email:', emailError);
      }

      // 3) Target Visit update if applicable
      //    (This logic used to be in handleStatusChange for "Complete")
      const isTargetCustomer = serviceOrder?.customerName?.includes('Target');
      const hasValidVisitId = !!selectedAppointment.VisitID;

      if (isTargetCustomer && hasValidVisitId) {
        const UPDATE_TARGET_VISIT_ENDPOINT =
          'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_target_visit';

        // We do "Check-out" for Complete
        const targetPayload = {
          visit_status: 'Check-out',
          check_out_time: new Date().toISOString(),
          visit_comments: 'The Technician has completed their visit.',
          visit_id: selectedAppointment.VisitID,
        };

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              console.log(`Geolocation for status "Complete":`, {
                latitude,
                longitude,
              });
              targetPayload.check_out_latitude = latitude;
              targetPayload.check_out_longitude = longitude;

              await fetch(UPDATE_TARGET_VISIT_ENDPOINT, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'x-api-key': API_KEY,
                },
                body: JSON.stringify(targetPayload),
              });
            },
            (error) => {
              console.error('Error obtaining geolocation:', error);
            }
          );
        } else {
          console.error('Geolocation is not supported by this browser.');
          await fetch(UPDATE_TARGET_VISIT_ENDPOINT, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': API_KEY,
            },
            body: JSON.stringify(targetPayload),
          });
        }
      } else {
        console.log('Appointment does not meet criteria for updating Target visit.');
      }
    } catch (err) {
      console.error('Error finalizing COMPLETE status:', err);
      setError('Failed to complete appointment. Please try again later.');
    }
  };

  /**
   * Handle status change for Dispatched / Depart / Arrive
   * If user tries to go to "Complete," we instead route them to signature capture first.
   */
  const handleStatusChange = async (newStatus) => {
    // For completion, directly open the signature modal without confirmation
    if (newStatus === 'Complete') {
      setPendingComplete(true);
      handleOpenSignatureModal();
      return;
    }

    if (!selectedAppointment) {
      console.error('No appointment selected.');
      return;
    }

    // For other statuses (Dispatched, Depart, Arrive):
    const serviceOrder = serviceOrders.find(
      (so) => so.serviceOrderNumber === selectedAppointment.ServiceOrderID
    );
    const isTargetCustomer = serviceOrder?.customerName?.includes('Target');
    const hasValidVisitId = !!selectedAppointment.VisitID;

    // Prepare the payload for updating the appointment status
    const payload = {
      ServiceOrderID: selectedAppointment.ServiceOrderID,
      AppointmentNumber: selectedAppointment.AppointmentNumber,
      Status: newStatus,
    };

    console.log('Updating appointment with payload:', payload);

    try {
      // Update the appointment status using the normal endpoint
      const response = await fetch(UPDATE_APPOINTMENT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Appointment updated successfully:', data);

      // Update the selected appointment's status in state
      setSelectedAppointment((prev) => ({
        ...prev,
        Status: newStatus,
      }));

      // Update the currentStatus state to reflect the new status
      setCurrentStatus(newStatus);

      // Update the appointments list to reflect the new status
      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === selectedAppointment.AppointmentNumber
            ? { ...appt, Status: newStatus }
            : appt
        )
      );

      // For statuses other than "Complete," no signature needed here.
      //  Update Target if relevant:
      if (isTargetCustomer && hasValidVisitId) {
        // **Update Target Visit Status** (Depart or Arrive)
        const UPDATE_TARGET_VISIT_ENDPOINT =
          'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_target_visit';

        let targetPayload = {};

        if (newStatus === 'Depart') {
          // En route
          targetPayload = {
            visit_status: 'En route',
            estimated_time_arrival: new Date(Date.now() + 60 * 60 * 1000).toISOString(),
            visit_comments: 'The technician is on the way.',
            technician_info: user.email,
            visit_id: selectedAppointment.VisitID,
            visitor_phone: serviceOrder.contactPhone || '480-926-1975',
          };

          console.log('Sending En route status to Target endpoint with payload:', targetPayload);
          await fetch(UPDATE_TARGET_VISIT_ENDPOINT, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': API_KEY,
            },
            body: JSON.stringify(targetPayload),
          });
        } else if (newStatus === 'Arrive') {
          // Check-in
          targetPayload = {
            visit_status: 'Check-in',
            check_in_time: new Date().toISOString(),
            visit_comments: 'The Technician has arrived and started work.',
            visit_id: selectedAppointment.VisitID,
          };

          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              async (position) => {
                const { latitude, longitude } = position.coords;
                console.log(`Geolocation for status "Arrive":`, {
                  latitude,
                  longitude,
                });
                targetPayload.check_in_latitude = latitude;
                targetPayload.check_in_longitude = longitude;

                await fetch(UPDATE_TARGET_VISIT_ENDPOINT, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': API_KEY,
                  },
                  body: JSON.stringify(targetPayload),
                });
              },
              (error) => {
                console.error('Error obtaining geolocation:', error);
              }
            );
          } else {
            console.error('Geolocation is not supported by this browser.');
            await fetch(UPDATE_TARGET_VISIT_ENDPOINT, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'x-api-key': API_KEY,
              },
              body: JSON.stringify(targetPayload),
            });
          }
        }
      } else {
        console.log('Appointment does not meet criteria for updating Target visit.');
      }
    } catch (err) {
      console.error('Error updating appointment status:', err);
      setError('Failed to update appointment status. Please try again later.');
    }
  };

  /**
   * Render appointment details including customer information and description.
   */
  const renderAppointmentDetails = () => {
    if (!selectedAppointment) return null;

    const serviceOrder = serviceOrders.find(
      (so) => so.serviceOrderNumber === selectedAppointment.ServiceOrderID
    );

    const isComplete = selectedAppointment.Status === 'Complete';
    const isOnHold = selectedAppointment.OnHold === 1;

    const isTargetCustomer = serviceOrder?.customerName?.includes('Target');

    // Construct the full address for Google Maps
    const fullAddress = `${serviceOrder?.address?.line1 || ''} ${
      serviceOrder?.address?.line2 || ''
    }, ${serviceOrder?.address?.city || ''} ${
      serviceOrder?.address?.state || ''
    } ${serviceOrder?.address?.zip || ''}`.trim();

    return (
      <div className="awf-detailsContent compact">
        {/* Customer Information Box */}
        <div className="awf-infoBox">
          {/* Customer */}
          <div className="awf-infoItem">
            <span className="awf-infoLabel">Customer</span>
            <span className="awf-infoValue">
              {serviceOrder?.customerName || 'N/A'}
            </span>
          </div>

          {/* Customer Order */}
          <div className="awf-infoItem">
            <span className="awf-infoLabel">Customer Order</span>
            <span className="awf-infoValue">
              {serviceOrder?.customerOrder || 'N/A'}
            </span>
          </div>

          {/* Visit ID - Only show if Target customer */}
          {isTargetCustomer && selectedAppointment.VisitID && (
            <div className="awf-infoItem">
              <span className="awf-infoLabel">Visit ID</span>
              <span className="awf-infoValue">{selectedAppointment.VisitID}</span>
            </div>
          )}

          {/* Address */}
          <div className="awf-infoItem">
            <span className="awf-infoLabel">Address</span>
            <span className="awf-infoValue">
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                  fullAddress
                )}`}
                target="_blank"
                rel="noopener noreferrer"
                className="awf-addressLink"
              >
                {serviceOrder?.address?.line1 || 'N/A'}
                {serviceOrder?.address?.line2 && (
                  <>
                    <br />
                    {serviceOrder.address.line2}
                  </>
                )}
                <br />
                {serviceOrder?.address?.city || 'N/A'},{' '}
                {serviceOrder?.address?.state || 'N/A'}{' '}
                {serviceOrder?.address?.zip || 'N/A'}
              </a>
            </span>
          </div>

          {/* Contact */}
          <div className="awf-infoItem">
            <span className="awf-infoLabel">Contact</span>
            <span className="awf-infoValue">
              {serviceOrder?.contactName || 'N/A'}
              <br />
              Phone: {serviceOrder?.contactPhone || 'N/A'}
            </span>
          </div>
        </div>

        {/* Description Box */}
        <div className="awf-descriptionBox">
          <span className="awf-infoLabel">Description</span>
          <span className="awf-infoValue">
            {serviceOrder?.description || 'N/A'}
          </span>
        </div>

        {/* Status and Hold Buttons */}
        <div
          className="awf-statusSection"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <h3>Current Status: {currentStatus}</h3>
            <div className="awf-statusButtons">{renderNextStatusButton()}</div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {!isComplete && (
              <>
                <button
                  className="awf-holdButton btn btn-warning"
                  onClick={handleHoldButtonClick}
                >
                  {isOnHold ? 'Take off Hold' : 'Put on Hold'}
                </button>
                <div style={{ margin: '10px 0' }}></div>
              </>
            )}
            {isOnHold && selectedAppointment.OnHoldNotes && (
              <div className="awf-holdReason">
                <strong>Reason:</strong> {selectedAppointment.OnHoldNotes}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  // We'll keep these spinner states for user feedback on updating statuses:
  const [isDeparting, setIsDeparting] = useState(false);
  const [isArriving, setIsArriving] = useState(false);
  const [isCompleting, setIsCompleting] = useState(false);

  /**
   * Render the next status button based on current status
   */
  const renderNextStatusButton = () => {
    const nextStatus = getNextStatus(currentStatus);
    const isOnHold = selectedAppointment?.OnHold;
    const isUpdatingStatus = isDeparting || isArriving || isCompleting;

    if (!nextStatus || isOnHold) return null;

    return (
      <button
        className="awf-statusButton"
        onClick={() => handleStatusChange(nextStatus)}
        disabled={isUpdatingStatus}
      >
        {isUpdatingStatus ? 'Updating...' : nextStatus}
      </button>
    );
  };

  // New state for handling API call status when adding items
  const [addingItem, setAddingItem] = useState(false);
  const [addItemError, setAddItemError] = useState(null);

  /**
   * Extract the UsrAppointment suffix from the Appointment Number
   */
  const getUsrAppointment = () => {
    if (!selectedAppointment || !selectedAppointment.AppointmentNumber) return null;
    console.log('AppointmentNumber:', selectedAppointment.AppointmentNumber);

    let usrAppointment = null;
    if (typeof selectedAppointment.AppointmentNumber === 'string') {
      const parts = selectedAppointment.AppointmentNumber.split('-');
      if (parts.length > 1) {
        usrAppointment = parseInt(parts[1], 10);
      } else if (!isNaN(parseInt(parts[0], 10))) {
        usrAppointment = parseInt(parts[0], 10);
      }
    } else if (typeof selectedAppointment.AppointmentNumber === 'number') {
      usrAppointment = selectedAppointment.AppointmentNumber;
    }

    console.log('Parsed UsrAppointment:', usrAppointment, typeof usrAppointment);
    return usrAppointment;
  };

  // ---------------
  // ADD ITEM
  // ---------------
  const handleAddItemToAcumatica = async () => {
    if (
      !selectedAppointment ||
      !selectedAppointment.ServiceOrderID ||
      !selectedPart ||
      !quantity
    ) {
      setAddItemError('Missing required information.');
      return;
    }

    const usrAppointment = getUsrAppointment();
    if (!usrAppointment) {
      setAddItemError('Invalid Appointment Number format.');
      return;
    }

    // Determine if the part is a special part
    const isSpecialPart = SPECIAL_PART_NUMBERS.includes(selectedPart.itemID);

    // Extract first name and last name initial from user.email
    const [firstName, lastName] = user.email.split('@')[0].split('.');
    const technicianName = `${firstName} ${lastName.charAt(0)}`;

    // Construct the modified description based on whether it's a special part
    let modifiedDescription;
    if (isSpecialPart) {
      modifiedDescription = `${selectedPart.itemDescription}, ${briefDescription} - ${technicianName}`;
    } else {
      modifiedDescription = `${selectedPart.itemDescription} - ${technicianName}`;
    }

    const isServiceItem = selectedPart.ACUItemType === 'Service';
    const isStockItem = selectedPart.itemType === 'Stock';

    let unitPrice = parseFloat(price);
    if (isNaN(unitPrice) || unitPrice <= 0) {
      unitPrice = parseFloat(selectedPart.DefaultPrice) || 0;
    }

    let unitCost = parseFloat(cost);
    if (isNaN(unitCost) || unitCost <= 0) {
      unitCost = parseFloat(selectedPart.DefaultCost) || 0;
    }

    let lineType;
    let estimatedDuration;
    let decimalDuration;

    let payloadDetails = {
      InventoryID: String(selectedPart.itemID),
      Description: modifiedDescription,
      UnitPrice: unitPrice,
      UnitCost: unitCost,
      UsrAppointment: parseInt(usrAppointment, 10),
      UsrTech: user.email,
    };

    if (isServiceItem) {
      lineType = 'Service';

      const [hoursStr, minutesStr] = quantity.split(':');
      const hours = parseInt(hoursStr, 10) || 0;
      const minutes = parseInt(minutesStr, 10) || 0;

      if (minutes < 0 || minutes >= 60) {
        setAddItemError('Minutes should be between 0 and 59.');
        return;
      }

      estimatedDuration = hours * 100 + minutes;
      decimalDuration = hours + minutes / 60;

      payloadDetails = {
        ...payloadDetails,
        LineType: lineType,
        Qty: decimalDuration,
        EstimatedDuration: estimatedDuration,
      };
    } else {
      lineType = isStockItem ? 'Inventory Item' : 'Non-Stock Item';
      payloadDetails = {
        ...payloadDetails,
        LineType: lineType,
        Qty: parseFloat(quantity),
      };
    }

    const payload = {
      serviceOrderNumber: selectedAppointment.ServiceOrderID,
      Details: [payloadDetails],
    };

    console.log('Payload being sent:', JSON.stringify(payload, null, 2));

    setAddingItem(true);
    setAddItemError(null);

    try {
      const response = await fetch(ADD_ITEM_TO_ACUMATICA_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `API responded with status ${response.status}: ${errorText}`
        );
      }

      const data = await response.json();
      console.log('Item added successfully:', data);

      fetchDetailItems(selectedAppointment.ServiceOrderID);
      handleCloseModal();
    } catch (error) {
      console.error('Error adding item to Acumatica:', error);
      setAddItemError('Failed to add item. Please try again.');
    } finally {
      setAddingItem(false);
    }
  };

  // ------------
  // IMAGE UPLOADS (to S3) - unchanged
  // ------------
  const [selectedImages, setSelectedImages] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [uploadingImages, setUploadingImages] = useState(false);
  const [uploadError, setUploadError] = useState(null);

  // NOTE: We keep your existing S3 logic for images
  const s3 = new AWS.S3({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });
  const bucketName = process.env.REACT_APP_AWS_BUCKET;

  const handleImageSelection = async (files) => {
    const compressionOptions = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedImages = [];
      for (const file of files) {
        const compressedFile = await imageCompression(file, compressionOptions);
        compressedImages.push({
          file: compressedFile,
          preview: URL.createObjectURL(compressedFile),
        });
      }

      setSelectedImages((prevImages) => [...prevImages, ...compressedImages]);
      setShowImageModal(true);
    } catch (err) {
      console.error('Error compressing images:', err);
      const fallbackImages = Array.from(files).map((file) => ({
        file,
        preview: URL.createObjectURL(file),
      }));
      setSelectedImages((prevImages) => [...prevImages, ...fallbackImages]);
      setShowImageModal(true);
    }
  };

  const handleUploadImagesClick = (event) => {
    event.preventDefault();
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.multiple = true;

    input.onchange = async (e) => {
      const files = Array.from(e.target.files);
      await handleImageSelection(files);
    };

    input.click();
  };

  const handleTakePhotoClick = (event) => {
    event.preventDefault();
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.capture = 'environment';

    input.onchange = async (e) => {
      const files = Array.from(e.target.files);
      await handleImageSelection(files);
    };

    input.click();
  };

  const handleImageModalClose = () => {
    setShowImageModal(false);
  };

  const handleDeleteImage = (index) => {
    setSelectedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  // We do NOT include signature in this images logic, since we're restoring
  // the old endpoint for signature only.
  const handleSubmitImages = async () => {
    setUploadingImages(true);
    setUploadError(null);

    try {
      if (!selectedAppointment?.ServiceOrderID) {
        throw new Error('No appointment or service order selected.');
      }
      const appointmentCustId = selectedAppointment.CustID || 'unknown';
      const s3Folder = `${appointmentCustId}/${selectedAppointment.ServiceOrderID}/${selectedAppointment.AppointmentNumber}`;
      console.log('S3 folder path is:', s3Folder);

      for (let i = 0; i < selectedImages.length; i++) {
        const { file } = selectedImages[i];
        const timestamp = Date.now();
        const fileName = file.name.replace(/\s+/g, '_');
        const key = `${s3Folder}/${timestamp}-${fileName}`;

        const uploadParams = {
          Bucket: bucketName,
          Key: key,
          Body: file,
          ContentType: file.type,
        };

        const result = await s3.upload(uploadParams).promise();
        console.log('Uploaded image:', result.Location);
      }

      //alert('Images uploaded successfully.');
      setSelectedImages([]);
      setShowImageModal(false);
      setGalleryRefreshCounter((prev) => prev + 1); // Trigger gallery refresh
    } catch (error) {
      console.error('Error uploading images:', error);
      setUploadError(error.message);
    } finally {
      setUploadingImages(false);
    }
  };

  const handleTestButtonClick = async () => {
    // We'll leave this as-is, to test the S3 logic if needed
    try {
      if (!selectedAppointment || !selectedAppointment.ServiceOrderID) {
        console.log('No appointment selected.');
        return;
      }
      const serviceOrder = serviceOrders.find(
        (so) => so.serviceOrderNumber === selectedAppointment.ServiceOrderID
      );
      const custId = serviceOrder?.CustID || serviceOrder?.customerOrder || 'unknown';
      const s3Folder = `${custId}/${selectedAppointment.ServiceOrderID}/${selectedAppointment.AppointmentNumber}`;

      if (!selectedImages || selectedImages.length === 0) {
        console.log('No images to upload.');
        return;
      }
      for (let i = 0; i < selectedImages.length; i++) {
        const { file } = selectedImages[i];
        const timestamp = new Date().getTime();
        const fileName = file.name.replace(/\s+/g, '_');
        const key = `${s3Folder}/${timestamp}-${fileName}`;

        const uploadParams = {
          Bucket: process.env.REACT_APP_AWS_BUCKET,
          Key: key,
          Body: file,
          ContentType: file.type,
        };

        const result = await s3.upload(uploadParams).promise();
        console.log('Uploaded file:', result.Location);
      }
      alert('Test upload completed successfully.');
    } catch (error) {
      console.error('Error uploading to AWS:', error);
      alert('Error uploading to AWS. See console for details.');
    }
  };

  /**
   * Render images section WITHOUT the signature button
   */
  const renderImagesSection = () => {
    return (
      <div className="awf-imagesSection">
        <h3>Images and Files</h3>
        <div className="awf-uploadButtons">
          <button
            className="awf-uploadButton"
            onClick={handleUploadImagesClick}
            aria-label="Upload Images"
          >
            <span className="awf-icon">📁</span>
          </button>
          <button
            className="awf-uploadButton"
            onClick={handleTakePhotoClick}
            aria-label="Take Photo"
          >
            <span className="awf-icon">📷</span>
          </button>
        </div>

        {showImageModal && (
          <Modal show={showImageModal} onHide={handleImageModalClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>Preview Images</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="awf-selectedImagesContainer">
                {selectedImages.length === 0 ? (
                  <p>No images selected.</p>
                ) : (
                  selectedImages.map((image, index) => (
                    <div key={index} className="awf-imagePreview">
                      <img
                        src={image.preview}
                        alt={`Selected ${index}`}
                        className="awf-thumbnail"
                      />
                      <button
                        className="awf-deleteImageButton"
                        onClick={() => handleDeleteImage(index)}
                        aria-label={`Delete image ${index + 1}`}
                      >
                        Delete
                      </button>
                    </div>
                  ))
                )}
              </div>
              {uploadError && <div className="awf-error">{uploadError}</div>}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={handleImageModalClose}
                disabled={uploadingImages}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmitImages}
                disabled={uploadingImages || selectedImages.length === 0}
              >
                {uploadingImages ? 'Uploading...' : 'Submit'}
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {selectedAppointment && (
          <div style={{ marginTop: '20px' }}>
            <ImageGallery
              custId={selectedAppointment?.CustID || 'unknown'}
              serviceOrder={selectedAppointment?.ServiceOrderID}
              activeAppointment={String(selectedAppointment.AppointmentNumber)}
              allowAddImages={false}
              refreshCounter={galleryRefreshCounter}
            />
          </div>
        )}
      </div>
    );
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignature(null);
  };

  //-----------
  // Customer (Permanent) Notes Section
  //-----------
  const renderCustomerNotes = () => {
    return (
      <div className="awf-customerNotesSection">
        {customerNotesLoading ? (
          <div className="awf-loading">
            <Spinner animation="border" role="status" size="sm"></Spinner>
            <span> Loading customer notes...</span>
          </div>
        ) : customerNotesError ? (
          <div className="awf-error">{customerNotesError}</div>
        ) : (
          <div className="card mb-3">
            <div
              className="card-header"
              style={{ backgroundColor: '#0066cc', color: 'white' }}
            >
              <strong>Permanent / Customer Notes</strong>
            </div>
            <div className="card-body">
              <div
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                }}
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(customerNotes),
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  // **Hold logic**
  const [showHoldModal, setShowHoldModal] = useState(false);
  const [holdReason, setHoldReason] = useState('');
  const [holdLoading, setHoldLoading] = useState(false);

  const handleHoldButtonClick = async () => {
    if (!selectedAppointment) return;

    const isOnHold =
      selectedAppointment.OnHold === 1 || selectedAppointment.OnHold === true;

    if (isOnHold) {
      // Directly remove hold
      await handleSaveHold();
    } else {
      // Show modal
      toggleHoldModal();
    }
  };

  const handleSaveHold = async () => {
    if (!selectedAppointment) return;

    setHoldLoading(true);

    const isOnHold =
      selectedAppointment.OnHold === 1 || selectedAppointment.OnHold === true;

    const payload = {
      ServiceOrderID: selectedAppointment.ServiceOrderID,
      AppointmentNumber: selectedAppointment.AppointmentNumber,
      OnHold: isOnHold ? 0 : 1,
      OnHoldNotes: isOnHold ? '' : holdReason,
    };

    try {
      const response = await fetch(
        'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_appointment_onhold',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': API_KEY,
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `API responded with status ${response.status}: ${errorText}`
        );
      }

      console.log('Hold status updated successfully.');

      setSelectedAppointment((prev) => ({
        ...prev,
        OnHold: isOnHold ? 0 : 1,
        OnHoldNotes: isOnHold ? '' : holdReason,
      }));

      setAppointments((prevAppointments) =>
        prevAppointments.map((appt) =>
          appt.AppointmentNumber === selectedAppointment.AppointmentNumber
            ? {
                ...appt,
                OnHold: isOnHold ? 0 : 1,
                OnHoldNotes: isOnHold ? '' : holdReason,
              }
            : appt
        )
      );

      const concatenatedAppointmentNumber = `${selectedAppointment.ServiceOrderID}-${selectedAppointment.AppointmentNumber}`;
      const serviceOrder = serviceOrders.find(
        (so) => so.serviceOrderNumber === selectedAppointment.ServiceOrderID
      );

      const customerName = serviceOrder?.customerName || 'N/A';
      const description = serviceOrder?.description || 'N/A';

      if (!isOnHold) {
        const subject = `${concatenatedAppointmentNumber} has been put on hold.`;
        const body = `
Customer: ${customerName}
Description: ${description}

${concatenatedAppointmentNumber} has been put on hold by ${user.email} with the following note:

${holdReason}
        `;

        try {
          await sendEmail({
            toAddress: 'dispatchgroup@collins-cs.com',
            subject,
            body,
            cc: 'glen.hollingsworth@collins-cs.com',
            replyTo: user.email,
          });

          console.log('Hold notification email sent successfully.');
        } catch (emailError) {
          console.error('Error sending hold notification email:', emailError);
        }

        toggleHoldModal();
      } else {
        const subject = `${concatenatedAppointmentNumber} has been taken off hold.`;
        const body = `
Customer: ${customerName}
Description: ${description}

${concatenatedAppointmentNumber} has been taken off hold by ${user.email}.
        `;

        try {
          await sendEmail({
            toAddress: user.email,
            cc: 'dispatchgroup@collins-cs.com',
            subject,
            body,
            replyTo: 'dispatchgroup@collins-cs.com',
          });

          console.log('Take off hold notification email sent successfully.');
        } catch (emailError) {
          console.error(
            'Error sending take off hold notification email:',
            emailError
          );
        }
      }
    } catch (error) {
      console.error('Error updating hold status:', error);
      setError('Failed to update hold status. Please try again later.');
    } finally {
      setHoldLoading(false);
      setHoldReason('');
    }
  };

  const handleCancelHold = () => {
    setHoldReason('');
    toggleHoldModal();
  };

  const toggleHoldModal = () => {
    setShowHoldModal(!showHoldModal);
  };

  const renderHoldModal = () => {
    if (!selectedAppointment) return null;

    const isOnHold =
      selectedAppointment.OnHold === 1 || selectedAppointment.OnHold === true;

    return (
      <Modal show={showHoldModal} onHide={toggleHoldModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{isOnHold ? 'Take off Hold' : 'Put on Hold'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="awf-formGroup">
            <label htmlFor="holdReason">
              Reason for {isOnHold ? 'Taking off Hold' : 'Putting on Hold'}
            </label>
            <textarea
              id="holdReason"
              value={holdReason}
              onChange={(e) => setHoldReason(e.target.value)}
              className="awf-textArea form-control"
              rows="4"
              placeholder="Enter the reason here..."
              disabled={isOnHold}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelHold}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveHold} disabled={holdLoading}>
            {holdLoading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
                Saving...
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  //-----------
  // CREATE PO
  //-----------
  const GET_VENDORS_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_vendors';
  const CREATE_PO_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_create_po';

  const [showCreatePOModal, setShowCreatePOModal] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState('');
  const [poNotes, setPONotes] = useState('');
  const [vendorsLoading, setVendorsLoading] = useState(false);
  const [vendorsError, setVendorsError] = useState(null);
  const [creatingPO, setCreatingPO] = useState(false);
  const [createPOError, setCreatePOError] = useState(null);
  const [createPOCurrentStep, setCreatePOCurrentStep] = useState(1);
  const [createdPONumber, setCreatedPONumber] = useState(null);

  const [vendorSearchTerm, setVendorSearchTerm] = useState('');
  const filteredVendors = vendors.filter((vendor) => {
    const vendorID = vendor.VendorID?.value || '';
    const vendorName = vendor.VendorName?.value || '';
    return (
      vendorID.toLowerCase().includes(vendorSearchTerm.toLowerCase()) ||
      vendorName.toLowerCase().includes(vendorSearchTerm.toLowerCase())
    );
  });
  const [cachedVendors, setCachedVendors] = useState([]);

  const fetchVendors = async (forceRefresh = false) => {
    if (!forceRefresh) {
      const cached = localStorage.getItem('cachedVendors');
      if (cached) {
        setVendors(JSON.parse(cached));
        return;
      }
    }

    setVendorsLoading(true);
    setVendorsError(null);
    console.log('Starting fetchVendors...');

    try {
      const response = await fetch(GET_VENDORS_ENDPOINT, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Fetched Vendors:', data);

      if (data.vendors && Array.isArray(data.vendors)) {
        setVendors(data.vendors);
        localStorage.setItem('cachedVendors', JSON.stringify(data.vendors));
      } else {
        throw new Error('Vendors data is not in expected format.');
      }
    } catch (err) {
      console.error('Error fetching vendors:', err);
      setVendorsError('Failed to load vendors. Please try again later.');
    } finally {
      setVendorsLoading(false);
      console.log('fetchVendors completed.');
    }
  };

  const handleRefreshVendors = () => {
    localStorage.removeItem('cachedVendors');
    fetchVendors(true);
  };

  const handleOpenCreatePOModal = () => {
    setShowCreatePOModal(true);
    fetchVendors();
  };

  const handleCloseCreatePOModal = () => {
    setShowCreatePOModal(false);
    setSelectedVendor('');
    setPONotes('');
    setCreatePOError(null);
    setCreatedPONumber(null);
  };

  const handleCreatePO = async () => {
    if (!selectedVendor) {
      setCreatePOError('Please select a vendor.');
      return;
    }

    if (!selectedAppointment) {
      setCreatePOError('No appointment selected.');
      return;
    }

    const selectedVendorObject = vendors.find(
      (vendor) => vendor.VendorID.value === selectedVendor
    );
    if (!selectedVendorObject) {
      setCreatePOError('Selected vendor not found.');
      return;
    }

    const vendorDisplayName = `${selectedVendorObject.VendorID.value} - ${selectedVendorObject.VendorName.value}`;

    const [firstName, lastName] = user.email.split('@')[0].split('.');
    const technicianName = `${firstName} ${lastName.charAt(0)}`;

    const payload = {
      serviceOrderNumber: selectedAppointment.ServiceOrderID,
      Appointment: selectedAppointment.AppointmentNumber,
      Technician: technicianName,
      Vendor: vendorDisplayName,
      Notes: poNotes,
      UsrTech: user.email,
    };

    setCreatingPO(true);
    setCreatePOError(null);

    try {
      const response = await fetch(CREATE_PO_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `API responded with status ${response.status}: ${errorText}`
        );
      }

      const data = await response.json();
      console.log('PO created successfully:', data);

      const poNumber = data?.data?.PONbr || null;
      setCreatedPONumber(poNumber);

      setCreatePOCurrentStep(2);

      await fetchDetailItems(selectedAppointment.ServiceOrderID);
    } catch (error) {
      console.error('Error creating PO:', error);
      setCreatePOError('Failed to create PO. Please try again.');
    } finally {
      setCreatingPO(false);
    }
  };

  const renderCreatePOModal = () => (
    <Modal show={showCreatePOModal} onHide={handleCloseCreatePOModal} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Create Purchase Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {creatingPO ? (
          <div className="awf-loading">
            <Spinner animation="border" role="status" size="sm"></Spinner>
            <span> Creating PO...</span>
          </div>
        ) : createdPONumber ? (
          <div>
            <h5>Purchase Order Created Successfully!</h5>
            <p>
              <strong>PO Number:</strong> {createdPONumber}
            </p>
            <p>Please note the PO number for your records.</p>
          </div>
        ) : (
          <div>
            {/* Vendor Search and Dynamic List */}
            <div className="awf-formGroup">
              <label>Search and Select Vendor</label>
              <input
                type="text"
                value={vendorSearchTerm}
                onChange={(e) => setVendorSearchTerm(e.target.value)}
                className="awf-textInput form-control"
                placeholder="Search by Vendor ID or Name..."
              />
              <div
                className="awf-vendorList"
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  marginTop: '5px',
                  padding: '5px',
                }}
              >
                {filteredVendors.length > 0 ? (
                  filteredVendors.map((vendor) => (
                    <div
                      key={vendor.VendorID.value}
                      className={`awf-vendorItem ${
                        selectedVendor === vendor.VendorID.value ? 'selected' : ''
                      }`}
                      onClick={() => setSelectedVendor(vendor.VendorID.value)}
                      style={{
                        padding: '8px',
                        cursor: 'pointer',
                        backgroundColor:
                          selectedVendor === vendor.VendorID.value ? '#e6f7ff' : 'transparent',
                      }}
                    >
                      {`${vendor.VendorID.value} - ${vendor.VendorName.value}`}
                    </div>
                  ))
                ) : (
                  <div className="awf-noVendors" style={{ padding: '8px', color: '#888' }}>
                    No vendors found.
                  </div>
                )}
              </div>
            </div>

            {/* Notes Input */}
            <div className="awf-formGroup" style={{ marginTop: '20px' }}>
              <label>Notes</label>
              <textarea
                className="awf-textArea form-control"
                value={poNotes}
                onChange={(e) => setPONotes(e.target.value)}
                rows="4"
                placeholder="Enter any additional notes here..."
              />
            </div>
            <label>Items purchased need to be entered as NS or Stock Items.</label>
          </div>
        )}
        {createPOError && <div className="awf-error">{createPOError}</div>}
      </Modal.Body>
      <Modal.Footer>
        {createdPONumber ? (
          <Button variant="primary" onClick={handleCloseCreatePOModal}>
            Close
          </Button>
        ) : (
          <div className="d-flex justify-content-between w-100">
            <Button variant="outline-secondary" onClick={() => fetchVendors(true)} disabled={vendorsLoading}>
              {vendorsLoading ? 'Refreshing...' : 'Refresh'}
            </Button>
            <div>
              <Button variant="secondary" onClick={handleCloseCreatePOModal} className="me-2">
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleCreatePO}
                disabled={creatingPO || !selectedVendor}
              >
                {creatingPO ? (
                  <>
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Creating...
                  </>
                ) : (
                  'Create PO'
                )}
              </Button>
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );

  /**
   * Render the Parts Section with styled cards
   */
  const renderPartsSection = () => {
    return (
      <div className="awf-partsSection">
        <h3>Parts</h3>

        <div className="d-flex mb-3">
          <Button variant="primary" className="me-2" onClick={() => setShowPartsModal(true)}>
            Add Parts
          </Button>
          <Button variant="success" onClick={handleOpenCreatePOModal}>
            Create PO
          </Button>
        </div>

        <div className="awf-detailItemsSection">{renderDetailItemsCards()}</div>

        {showCreatePOModal && renderCreatePOModal()}
      </div>
    );
  };

  const refreshParts = async () => {
    setPartsLoading(true);
    setPartsError(null);
    console.log('Refreshing parts...');

    try {
      const response = await fetch(GET_ALL_ITEMS_ENDPOINT, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`API Error Response: ${errorText}`);
        throw new Error(`API responded with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Refreshed Parts:', data);

      if (Array.isArray(data)) {
        setParts(data);
        setCachedVendors(data);
      } else if (data.body) {
        const parsedData = JSON.parse(data.body);
        setParts(parsedData);
        setCachedVendors(parsedData);
      } else {
        throw new Error('Unexpected API response format');
      }
    } catch (err) {
      console.error('Error refreshing parts:', err);
      setPartsError('Failed to refresh parts. Please try again later.');
    } finally {
      setPartsLoading(false);
      console.log('refreshParts completed.');
    }
  };

  /**
   * Render the form for entering part details
   */
  const [briefDescription, setBriefDescription] = useState('');

  const renderPartDetailsForm = () => {
    if (!selectedPart) return null;

    const isServiceItem = selectedPart.ACUItemType === 'Service';

    const handleTimeChange = (hours, minutes) => {
      setQuantity(`${hours}:${minutes}`);
    };

    const isSpecialPart = selectedPart && SPECIAL_PART_NUMBERS.includes(selectedPart.itemID);

    const isMeaningfulValue = (value) => {
      return value && value !== '0' && value !== 'N/A' && value !== 'null';
    };

    return (
      <div className="awf-partDetailsForm">
        <div className="awf-selectedPartInfo">
          <strong>Selected Part:</strong>
          <p>
            <strong>ID:</strong> {selectedPart.itemID}
            <br />
            <strong>Description:</strong> {selectedPart.itemDescription}
            <br />
            {selectedPart.price &&
              selectedPart.price !== '0' &&
              selectedPart.price !== 'N/A' && (
                <p>
                  <strong>Price:</strong> ${selectedPart.price}
                </p>
              )}
          </p>
        </div>

        <div className="awf-formGroup">
          <label>{isServiceItem ? 'Estimated Duration' : 'Quantity'}</label>
          {isServiceItem ? (
            <div className="time-picker">
              <select
                onChange={(e) =>
                  handleTimeChange(e.target.value, quantity.split(':')[1] || '00')
                }
                value={quantity.split(':')[0] || '00'}
              >
                {Array.from({ length: 24 }, (_, i) => (
                  <option key={i} value={String(i).padStart(2, '0')}>
                    {String(i).padStart(2, '0')}
                  </option>
                ))}
              </select>
              :
              <select
                onChange={(e) =>
                  handleTimeChange(quantity.split(':')[0] || '00', e.target.value)
                }
                value={quantity.split(':')[1] || '00'}
              >
                {['00', '15', '30', '45'].map((minute) => (
                  <option key={minute} value={minute}>
                    {minute}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <input
              type="text"
              value={quantity}
              onChange={(e) => setQuantity(e.target.value.replace(/[^0-9]/g, ''))}
              placeholder="Enter quantity"
            />
          )}
        </div>

        {isSpecialPart && (
          <div className="awf-formGroup">
            <label>Brief Description</label>
            <input
              type="text"
              value={briefDescription}
              onChange={(e) => setBriefDescription(e.target.value)}
              placeholder="Enter brief description"
            />
          </div>
        )}

        <div className="awf-formGroup">
          {isSpecialPart || isMeaningfulValue(price) ? (
            <div className="awf-formGroup">
              <label>Customer Price</label>
              <input
                type="text"
                value={`$${price}`}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '');
                  setPrice(value);
                }}
                placeholder="$0.00"
                inputMode="decimal"
                disabled={!isSpecialPart}
                style={{ backgroundColor: !isSpecialPart ? '#f0f0f0' : 'white' }}
              />
            </div>
          ) : null}

          {isSpecialPart || isMeaningfulValue(cost) ? (
            <div className="awf-formGroup">
              <label>Our Cost to Purchase</label>
              <input
                type="text"
                value={isSpecialPart ? `$${cost}` : `$${selectedPart?.cost || '0.00'}`}
                onChange={(e) => {
                  const value = e.target.value.replace(/[^0-9.]/g, '');
                  setCost(value);
                }}
                placeholder="$0.00"
                inputMode="decimal"
                disabled={!isSpecialPart}
                style={{ backgroundColor: !isSpecialPart ? '#f0f0f0' : 'white' }}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderAddPartsModal = () => (
    <Modal show={showPartsModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {currentStep === 1 ? 'Select Part' : 'Enter Quantity, Cost, and Price'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentStep === 1 ? (
          <div>
            <div className="awf-formGroup">
              <label>Search Parts</label>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="awf-textInput"
                placeholder="Search by part number or description..."
              />
            </div>

            <div
              className="awf-partsList"
              style={{
                maxHeight: '400px',
                overflowY: 'auto',
              }}
            >
              {filteredParts.map((part, index) => (
                <div
                  key={index}
                  className={`awf-partItem ${
                    selectedPart === part ? 'selected' : ''
                  }`}
                  onClick={() => setSelectedPart(part)}
                  style={{
                    cursor: 'pointer',
                    padding: '10px',
                    border:
                      selectedPart === part
                        ? '2px solid blue'
                        : '1px solid gray',
                  }}
                >
                  <strong>{part.itemID}</strong>: {part.itemDescription}
                </div>
              ))}
            </div>
          </div>
        ) : (
          renderPartDetailsForm()
        )}
      </Modal.Body>
      <Modal.Footer>
        {currentStep === 2 ? (
          <>
            <Button
              variant="secondary"
              onClick={handleBackStep}
              disabled={addingItem}
            >
              Back
            </Button>
            <Button
              variant="primary"
              onClick={handleAddItemToAcumatica}
              disabled={addingItem}
            >
              {addingItem ? 'Finishing...' : 'Finish'}
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="secondary"
              onClick={refreshParts}
              disabled={partsLoading}
            >
              {partsLoading ? 'Refreshing...' : 'Refresh'}
            </Button>
            <Button
              variant="primary"
              onClick={handleNextStep}
              disabled={!selectedPart || addingItem}
            >
              Next
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );

  /**
   * Detail Items Cards
   */
  const renderDetailItemsCards = () => {
    if (detailItemsLoading) {
      return (
        <div className="awf-loading">
          <Spinner animation="border" role="status" size="sm"></Spinner>
          <span> Loading detail items...</span>
        </div>
      );
    }

    if (detailItemsError) {
      return <div className="awf-error">{detailItemsError}</div>;
    }

    if (detailItems.length === 0) {
      return (
        <div className="awf-noDetailItems">
          No detail items found for this service order.
        </div>
      );
    }

    return (
      <Row xs={1} md={2} lg={3} className="g-4">
        {detailItems.map((item) => {
          const uniqueKey = `${item.ServiceOrderID}-${item.LineRef}`;
          const isCurrentAppointment =
            item.AppointmentNumber === selectedAppointment.AppointmentNumber;

          return (
            <Col key={uniqueKey}>
              <Card className={`awf-detailCard ${isCurrentAppointment ? 'highlight' : ''}`}>
                <Card.Body>
                  <Card.Title>
                    <strong>{item.Description || 'N/A'}</strong>
                  </Card.Title>

                  <Card.Text>
                    <strong>Qty:</strong> {item.Qty}
                  </Card.Text>
                  <Card.Text>
                    <strong>Total Price:</strong>{' '}
                    {new Intl.NumberFormat('en-US', {
                      style: 'currency',
                      currency: 'USD',
                    }).format(item.ExtPrice)}
                  </Card.Text>
                  <Card.Text>
                    <strong>Appointment:</strong> {item.UsrAppointment}
                  </Card.Text>
                  <Card.Text>
                    <strong>Line:</strong> {item.LineRef}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  };

  const handleMenuItemClick = (section) => {
    setActiveSection(section);
  };

  const [collapsed, setCollapsed] = useState(true); // Initialize as collapsed
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleCloseModal = () => {
    setShowPartsModal(false);
    setCurrentStep(1);
    setSelectedPart(null);
    setQuantity('');
    setPrice('');
    setCost('');
    setBriefDescription('');
  };

  //-----------
  // Geolocation
  //-----------
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [locationError, setLocationError] = useState(null);

  const getCurrentGeolocation = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by your browser.'));
      } else {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            });
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  };

  // We do not call "updateTargetVisit" for Complete here, because
  // that logic is now in doCompleteAfterSignature.

  // For resizing signature canvas in the modal
  const [canvasWidth, setCanvasWidth] = useState(500);
  const [canvasHeight, setCanvasHeight] = useState(200);

  useEffect(() => {
    const updateCanvasSize = () => {
      const container = document.getElementById('signature-pad-container');
      if (container) {
        const width = container.offsetWidth;
        setCanvasWidth(width);
        setCanvasHeight(200);
      }
    };
    updateCanvasSize();
    window.addEventListener('resize', updateCanvasSize);
    return () => {
      window.removeEventListener('resize', updateCanvasSize);
    };
  }, []);

  useEffect(() => {
    if (showSignatureModal && signatureCanvasRef.current) {
      // Give the canvas time to render, then adjust
      setTimeout(() => {
        const canvas = signatureCanvasRef.current.getCanvas();
        if (canvas) {
          const ratio = Math.max(window.devicePixelRatio || 1, 1);
          // Set the canvas width and height based on its display size * ratio
          canvas.width = canvas.offsetWidth * ratio;
          canvas.height = canvas.offsetHeight * ratio;
          // Scale the drawing context to account for the increased size
          const context = canvas.getContext('2d');
          context.scale(ratio, ratio);
        }
      }, 100);
    }
  }, [showSignatureModal]);

  return (
    <div className="AwF-AppointmentWorkFlow">
      {selectedAppointment && (
        <div className="awf-toggleButton" onClick={toggleSidebar}>
          <div className={`hamburger ${!collapsed ? 'open' : ''}`}></div>
        </div>
      )}

      {selectedAppointment && (
        <div
          className={`awf-overlay ${!collapsed ? 'open' : ''}`}
          onClick={toggleSidebar}
        ></div>
      )}

      {selectedAppointment && (
        <div className={`awf-sidebarContainer ${!collapsed ? 'open' : ''}`}>
          <ProSidebar collapsed={collapsed} width="80px">
            <Menu iconShape="square">
              <MenuItem
                icon={<FaTruck size={32} />}
                active={activeSection === 'status'}
                onClick={() => handleMenuItemClick('status')}
              />
              <MenuItem
                icon={<FaRegStickyNote size={32} />}
                active={activeSection === 'notes'}
                onClick={() => handleMenuItemClick('notes')}
              />
              <MenuItem
                icon={<FaCamera size={32} />}
                active={activeSection === 'images'}
                onClick={() => handleMenuItemClick('images')}
              />
              <MenuItem
                icon={<FaTools size={32} />}
                active={activeSection === 'parts'}
                onClick={() => handleMenuItemClick('parts')}
              />
            </Menu>
          </ProSidebar>
        </div>
      )}

      <div className="awf-content-spacing" onClick={() => setCollapsed(true)}>
        <div className="awf-card">
          <div className="awf-cardHeader">
            <h2 className="awf-cardTitle">
              {selectedAppointment
                ? `Appointment ${selectedAppointment.ServiceOrderID}-${selectedAppointment.AppointmentNumber}`
                : 'Appointments'}
            </h2>
            {selectedAppointment && (
              <div className="awf-cardSubHeader">
                <div>{selectedAppointment.Status}</div>
              </div>
            )}
          </div>
          <div className="awf-cardContent">
            {!selectedAppointment ? (
              <div className="awf-currentAppointmentsContent">
                {renderAppointmentsListing()}
              </div>
            ) : (
              <>
                <nav className="awf-breadcrumbs">
                  <span
                    className="awf-breadcrumb-link"
                    onClick={handleBackToList}
                    style={{
                      cursor: 'pointer',
                      color: '#007bff',
                      textDecoration: 'underline',
                    }}
                  >
                    Appointments
                  </span>
                  <span className="awf-breadcrumb-separator">/</span>
                  <span className="awf-breadcrumb-current">
                    {`${selectedAppointment.ServiceOrderID}-${selectedAppointment.AppointmentNumber}`}
                  </span>
                </nav>

                <div className="awf-appointmentDetailLayout">
                  <div className="awf-mainContent">
                    {activeSection === 'status' && (
                      <>{renderAppointmentDetails()}</>
                    )}

                    {activeSection === 'notes' && (
                      <div>
                        <AppointmentNotes
                          serviceOrderID={selectedAppointment.ServiceOrderID}
                          username={user?.email || 'UnknownUser'}
                          appointment={`${selectedAppointment.ServiceOrderID}-${selectedAppointment.AppointmentNumber}`}
                        />
                        <br />
                        <br />
                        {renderCustomerNotes()}
                      </div>
                    )}

                    {activeSection === 'images' && renderImagesSection()}
                    {activeSection === 'parts' && renderPartsSection()}
                  </div>
                </div>

                {showPartsModal && renderAddPartsModal()}
              </>
            )}
          </div>
        </div>
      </div>

      {showHoldModal && selectedAppointment && renderHoldModal()}

      {/* Signature Modal for "Complete" */}
      <Modal
        show={showSignatureModal}
        onHide={handleCloseSignatureModal}
        centered
        className="awf-signatureModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Capture Signature</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div id="signature-pad-container" className="awf-signatureCapture">
            <SignatureCanvas
              ref={signatureCanvasRef}
              penColor="black"
              canvasProps={{
                className: 'signature-canvas',
                width: canvasWidth,
                height: canvasHeight,
              }}
            />
            <button className="awf-clearSignatureButton" onClick={clearModalSignature}>
              Clear Signature
            </button>
          </div>
          {signatureError && <div className="awf-errorMessage">{signatureError}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSignatureModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveSignature} disabled={uploadingSignature}>
            {uploadingSignature ? 'Saving...' : 'Save Signature'}
          </Button>
        </Modal.Footer>
      </Modal>

      {showCreatePOModal && renderCreatePOModal()}
    </div>
  );

  /**
   * Render the Appointments Listing
   */
  function renderAppointmentsListing() {
    if (appointmentsLoading) {
      return (
        <div className="awf-loading">
          <Spinner animation="border" role="status" size="sm"></Spinner>
          <span> Loading appointments...</span>
        </div>
      );
    }

    if (error) {
      return <div className="awf-error">{error}</div>;
    }

    if (appointments.length === 0) {
      return <div className="awf-noAppointments">No appointments found.</div>;
    }

    return (
      <Row xs={1} md={2} lg={3} className="g-4">
        {appointments.map((appointment) => {
          const serviceOrder = serviceOrders.find(
            (so) => so.serviceOrderNumber === appointment.ServiceOrderID
          );

          const concatenatedId = `${appointment.ServiceOrderID}-${appointment.AppointmentNumber}`;
          const isOnHold =
            appointment.Status === 'On Hold' ||
            appointment.OnHold === 1 ||
            appointment.OnHold === true;

          const timeZone = 'UTC'; 
          const appointmentDate = appointment.AppointmentDate
            ? format(
                toZonedTime(parseISO(appointment.AppointmentDate), timeZone),
                'MM/dd/yyyy'
              )
            : 'N/A';

          return (
            <Col key={concatenatedId}>
              <Card
                className={`awf-appointmentCard ${isOnHold ? 'on-hold' : ''}`}
                onClick={() => handleSelectAppointment(appointment)}
              >
                <Card.Body>
                  <Card.Title>{concatenatedId}</Card.Title>
                  <Card.Text>
                    <strong>Customer:</strong> {serviceOrder?.customerName || 'N/A'}
                  </Card.Text>
                  <Card.Text>
                    <strong>Appointment Date:</strong> {appointmentDate}
                  </Card.Text>
                  <Card.Text>
                    <strong>Status:</strong> {appointment.Status || 'N/A'}
                  </Card.Text>
                  {isOnHold && (
                    <Card.Text>
                      <strong style={{ color: 'red' }}>On Hold</strong>
                    </Card.Text>
                  )}
                </Card.Body>
              </Card>
            </Col>
          );
        })}
      </Row>
    );
  }
}

// Inject extra highlight styles
const styles = `
  .highlight {
    background-color: #e6f7ff;
  }
`;
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
