import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "bootstrap/dist/css/bootstrap.min.css";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import UserProvider from "./UserProvider";
import { ThemeProvider } from './components/context/ThemeContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Import the version check utility
async function checkForNewVersion() {
  try {
    const response = await fetch('/version.json', { cache: 'no-store' });
    const { version } = await response.json();
    
    const currentVersion = localStorage.getItem('app_version');

    if (currentVersion && version !== currentVersion) {
      console.log("New version detected. Reloading...");
      localStorage.setItem('app_version', version);
      window.location.reload(); // Force refresh
    } else {
      localStorage.setItem('app_version', version);
    }
  } catch (error) {
    console.error("Error checking app version:", error);
  }
}

// Set up the MSAL instance
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider>
      <MsalProvider instance={msalInstance}>
        <UserProvider>
          <App />
        </UserProvider>
      </MsalProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// Unregister any existing service workers
serviceWorkerRegistration.unregister();

// Check for a new version every 60 seconds
setInterval(checkForNewVersion, 60000);
