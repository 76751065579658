// ServiceOrderTable.jsx

import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ServiceOrderContext from './context/ServiceOrderContext';
import UserContext from './context/UserContext';
import './css/ServiceOrderTable.css';
import { ResizableBox } from 'react-resizable';
import 'react-resizable/css/styles.css';
import { FaStickyNote, FaBullseye, FaSpinner, FaChevronRight, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';
import 'bootstrap/dist/css/bootstrap.min.css';

// Define the API endpoints and API key from environment variables
const PMWA_NOTES_ENDPOINT =
  process.env.REACT_APP_CONEXCS_PMWA_NOTES_ENDPOINT ||
  'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_pmwa_notes';
const PMWA_SEND_MESSAGE_ENDPOINT =
  process.env.REACT_APP_CONEXCS_PMWA_SEND_MESSAGE_ENDPOINT ||
  'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_send_target_pmwa_message';
const PMWA_NEEDS_RESPONSE_ENDPOINT =
  process.env.REACT_APP_CONEXCS_PMWA_NEEDS_RESPONSE_ENDPOINT ||
  'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_PMWA_needs_response';
const PMWA_ACKNOWLEDGE_ENDPOINT =
  process.env.REACT_APP_CONEXCS_PMWA_ACKNOWLEDGE_ENDPOINT ||
  'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_acknowledge_PMWA_notes';
const API_KEY = process.env.REACT_APP_CONEXCS_API_KEY; // Ensure this is set in your environment variables

// Helper function to retrieve acknowledgment value
const getAcknowledgmentValue = (note) => {
  if ('acknowledgment' in note) {
    return note.acknowledgment;
  } else if ('acknowledgement' in note) {
    return note.acknowledgement;
  }
  return undefined;
};

export default function ServiceOrderTable({ showPMWAColumn = false }) {
  const { serviceOrders, setServiceOrders } = useContext(ServiceOrderContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  // Local states for table
  const [searchTerm, setSearchTerm] = useState('');
  const [sortProperties, setSortProperties] = useState({
    columnKey: 'pmwaStatus', // Set PMWA as the default sort column
    descending: true,        // Red (unacknowledged) on top by default
  });
  const [filteredAndSortedData, setFilteredAndSortedData] = useState([]);

  // ----------------------------------------------------------------
  // NEW: Individual column filters
  // ----------------------------------------------------------------
  const [soNumberFilter, setSoNumberFilter] = useState(''); // New SO# filter
  const [customerFilter, setCustomerFilter] = useState('');
  const [descriptionFilter, setDescriptionFilter] = useState('');
  const [customerOrderFilter, setCustomerOrderFilter] = useState('');
  const [addressFilter, setAddressFilter] = useState('');

  // Department filter
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [deptFiltersOpen, setDeptFiltersOpen] = useState(false);

  // Grab unique departments from current serviceOrders
  useEffect(() => {
    // Collect unique departments
    const uniqueDeps = Array.from(
      new Set(serviceOrders.map((o) => o.department || 'N/A'))
    );
    // Sort them alphabetically for a nicer UI
    uniqueDeps.sort();
    setDepartmentList(uniqueDeps);

    // By default, select all
    setSelectedDepartments(uniqueDeps);
  }, [serviceOrders]);

  // Handler to toggle the department filter panel
  const toggleDeptFiltersOpen = () => {
    setDeptFiltersOpen((prev) => !prev);
  };

  // Handler for toggling one department
  const toggleOneDepartment = (dept) => {
    setSelectedDepartments((prev) => {
      // If already selected, remove it
      if (prev.includes(dept)) {
        return prev.filter((d) => d !== dept);
      }
      // Otherwise add it
      return [...prev, dept];
    });
  };

  // Handler for Select All / Unselect All
  const handleSelectAllDepartments = () => {
    if (selectedDepartments.length === departmentList.length) {
      // Currently all selected => unselect all
      setSelectedDepartments([]);
    } else {
      // Not all selected => select all
      setSelectedDepartments(departmentList);
    }
  };

  // Default column widths (in pixels)
  const defaultColumnWidths = {
    pmwaStatus: 100,
    soNumber: 90,
    customerName: 200,
    details: 250,
    customerOrder: 150,
    address: 200,
    department: 150,
    lastUpdate: 160,
  };

  // On component mount, load widths from localStorage, or fallback to defaults
  const [columnWidths, setColumnWidths] = useState(() => {
    const fromStorage = localStorage.getItem('serviceOrderTableColumnWidths');
    return fromStorage ? JSON.parse(fromStorage) : defaultColumnWidths;
  });

  // Persist column widths whenever they change
  const handleResize = (columnKey, newWidth) => {
    setColumnWidths((prev) => {
      const updated = { ...prev, [columnKey]: newWidth };
      localStorage.setItem('serviceOrderTableColumnWidths', JSON.stringify(updated));
      return updated;
    });
  };

  // Modal states
  const [notesModalOpen, setNotesModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [notes, setNotes] = useState([]); // Fetched notes
  const [loadingNotes, setLoadingNotes] = useState(false);
  const [error, setError] = useState(null);

  // New Note State
  const [newNoteContent, setNewNoteContent] = useState('');
  const [sendStatus, setSendStatus] = useState(null); // 'success' | 'error' | null
  const [sendErrorMessage, setSendErrorMessage] = useState('');

  // State variables
  const [isSending, setIsSending] = useState(false);
  const [unacknowledgedOrders, setUnacknowledgedOrders] = useState([]); // New state for unacknowledged orders

  // New State for Acknowledgment Button
  const [hasUnacknowledgedNotes, setHasUnacknowledgedNotes] = useState(false);

  // New state to ensure we load the notes only once per initial orders load
  const [hasLoadedNotes, setHasLoadedNotes] = useState(false);

  // Fetch unacknowledged orders function for reuse
  const fetchUnacknowledgedOrders = async () => {
    try {
      const response = await fetch(PMWA_NEEDS_RESPONSE_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({}), // Send an empty body if no payload is expected
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Error: ${response.statusText}`);
      }

      const unackData = await response.json();
      setUnacknowledgedOrders(unackData);
    } catch (error) {
      console.error('Failed to fetch unacknowledged orders:', error);
    }
  };

  // useEffect to fetch unacknowledged orders on component mount
  useEffect(() => {
    fetchUnacknowledgedOrders();
  }, [PMWA_NEEDS_RESPONSE_ENDPOINT, API_KEY]);

  // Open/close modal
  const handleOpenNotesModal = async (order) => {
    setSelectedOrder(order);
    setNotesModalOpen(true);
    await fetchNotes(order.customerOrder);
  };

  const handleCloseNotesModal = () => {
    setNotesModalOpen(false);
    setSelectedOrder(null);
    setNotes([]);
    setError(null);
    setNewNoteContent('');
    setSendStatus(null);
    setSendErrorMessage('');
    setHasUnacknowledgedNotes(false);
  };

  // Fetch notes from API
  const fetchNotes = async (customerOrderNumber) => {
    setLoadingNotes(true);
    setError(null);
    try {
      const response = await fetch(PMWA_NOTES_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({ work_order_number: customerOrderNumber }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.log('API Error Response:', errorData);
        throw new Error(errorData.message || `Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Fetched Notes Data:', data);
      setNotes(data);

      // Update the lastPMWANote for the selected order based on the latest note.
      if (data && data.length > 0) {
        // Sort descending by create_date so the most recent is at index 0.
        const sortedNotes = [...data].sort(
          (a, b) => new Date(b.create_date) - new Date(a.create_date)
        );
        const latestNote = sortedNotes[0];
        if (latestNote) {
          setSelectedOrder((prevOrder) => {
            if (prevOrder && prevOrder.customerOrder === customerOrderNumber) {
              const updatedOrder = { ...prevOrder, lastPMWANote: latestNote };
              // Also update serviceOrders so the table row reflects the new update info.
              setServiceOrders((prevOrders) =>
                prevOrders.map((order) =>
                  order.customerOrder === customerOrderNumber
                    ? { ...order, lastPMWANote: latestNote }
                    : order
                )
              );
              return updatedOrder;
            }
            return prevOrder;
          });
        }
      } else {
        // If no notes exist, clear out lastPMWANote.
        setSelectedOrder((prevOrder) => {
          if (prevOrder && prevOrder.customerOrder === customerOrderNumber) {
            const updatedOrder = { ...prevOrder, lastPMWANote: null };
            setServiceOrders((prevOrders) =>
              prevOrders.map((order) =>
                order.customerOrder === customerOrderNumber
                  ? { ...order, lastPMWANote: null }
                  : order
              )
            );
            return updatedOrder;
          }
          return prevOrder;
        });
      }

      // Check if there are any unacknowledged notes.
      const hasUnacknowledged = data.some((note) => {
        const acknowledgment = getAcknowledgmentValue(note);
        console.log(`Note ID: ${note.work_log_id}, Acknowledgment: ${acknowledgment}`);
        return acknowledgment === null;
      });

      console.log('Has Unacknowledged Notes:', hasUnacknowledged);
      setHasUnacknowledgedNotes(hasUnacknowledged);
    } catch (err) {
      console.error('Failed to fetch notes:', err);
      setError(err.message || 'Failed to fetch notes.');
    } finally {
      setLoadingNotes(false);
    }
  };

  // Handle Send Button Click
  const handleSendNote = async () => {
    if (newNoteContent.trim() === '') {
      return; // Do not send empty notes
    }

    setIsSending(true);

    // Prepare the payload
    const payload = {
      work_order_number: selectedOrder.customerOrder,
      description_long_text: newNoteContent.trim(),
      created_by: `CCS - ${user.display_name}`,
    };

    try {
      setSendStatus(null);
      setSendErrorMessage('');
      const response = await fetch(PMWA_SEND_MESSAGE_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Error: ${response.statusText}`);
      }

      // Re-fetch the notes
      await fetchNotes(selectedOrder.customerOrder);

      // Re-fetch unacknowledged orders
      await fetchUnacknowledgedOrders();

      setSendStatus('success');
      setNewNoteContent('');
    } catch (err) {
      console.error('Failed to send note:', err);
      setSendStatus('error');
      setSendErrorMessage(err.message || 'Failed to send note.');
    } finally {
      setIsSending(false);
    }
  };

  // Handle Acknowledge Notes Button Click
  const handleAcknowledgeNotes = async () => {
    if (!selectedOrder) return;

    setIsSending(true);

    const payload = {
      work_order_number: selectedOrder.customerOrder,
      acknowledged_by: user.display_name,
    };

    try {
      const response = await fetch(PMWA_ACKNOWLEDGE_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || `Error: ${response.statusText}`);
      }

      setUnacknowledgedOrders((prev) =>
        prev.filter((unack) => unack.work_order_number !== selectedOrder.customerOrder)
      );

      await fetchNotes(selectedOrder.customerOrder);

      setSendStatus('success');
      setSendErrorMessage('');
    } catch (err) {
      console.error('Failed to acknowledge notes:', err);
      setSendStatus('error');
      setSendErrorMessage(err.message || 'Failed to acknowledge notes.');
    } finally {
      setIsSending(false);
    }
  };

  // Filtering + Sorting
  useEffect(() => {
    /**
     * This function returns a priority:
     *  2 = HQ_LVMUpdate
     *  1 = unacknowledged (but not HQ_LVMUpdate)
     *  0 = not unacknowledged
     */
    const getUnackPriority = (order) => {
      const unackItem = unacknowledgedOrders.find(
        (u) => u.work_order_number === order.customerOrder
      );
      if (!unackItem) return 0;
      if (unackItem.isHQ_LVMUpdate) return 2;
      return 1;
    };

    const getFilteredAndSortedData = () => {
      if (!Array.isArray(serviceOrders)) return [];

      // 1) Filter by new column filters
      let filteredData = serviceOrders.filter((order) => {
        // The global searchTerm filter (existing)
        // If you'd rather remove the old searchTerm, remove this block
        if (
          searchTerm &&
          !Object.values(order)
            .some((val) => val?.toString().toLowerCase().includes(searchTerm.toLowerCase()))
        ) {
          return false;
        }

        // SO# Filter
        if (
          soNumberFilter &&
          !order.serviceOrderNumber?.toLowerCase().includes(soNumberFilter.toLowerCase())
        ) {
          return false;
        }

        // Customer Filter
        if (
          customerFilter &&
          !order.customerName?.toLowerCase().includes(customerFilter.toLowerCase())
        ) {
          return false;
        }

        // Description Filter
        if (
          descriptionFilter &&
          !order.details?.toLowerCase().includes(descriptionFilter.toLowerCase())
        ) {
          return false;
        }

        // Customer Order Filter
        if (
          customerOrderFilter &&
          !order.customerOrder?.toLowerCase().includes(customerOrderFilter.toLowerCase())
        ) {
          return false;
        }

        // Address Filter
        if (
          addressFilter &&
          !order.address?.toLowerCase().includes(addressFilter.toLowerCase())
        ) {
          return false;
        }

        // Department Filter (must match one of the selected)
        if (selectedDepartments.length > 0 && !selectedDepartments.includes(order.department)) {
          return false;
        }

        return true;
      });

      // 2) Sort
      const { columnKey, descending } = sortProperties;
      filteredData = filteredData.slice().sort((a, b) => {
        if (columnKey === 'pmwaStatus') {
          const aPriority = getUnackPriority(a);
          const bPriority = getUnackPriority(b);
          if (aPriority < bPriority) return descending ? 1 : -1;
          if (aPriority > bPriority) return descending ? -1 : 1;
          return 0;
        } else if (columnKey === 'soNumber') {
          const aVal = a.serviceOrderNumber || '';
          const bVal = b.serviceOrderNumber || '';

          // Strip out everything but digits (turning "FS123456" -> "123456")
          const aNum = parseInt(aVal.replace(/\D/g, ''), 10) || 0;
          const bNum = parseInt(bVal.replace(/\D/g, ''), 10) || 0;

          if (aNum < bNum) return descending ? 1 : -1;
          if (aNum > bNum) return descending ? -1 : 1;
          return 0;
        } else if (columnKey === 'lastUpdate') {
          const aVal = new Date(a.lastPMWANote?.create_date || 0).getTime();
          const bVal = new Date(b.lastPMWANote?.create_date || 0).getTime();
          if (aVal < bVal) return descending ? 1 : -1;
          if (aVal > bVal) return descending ? -1 : 1;
          return 0;
        } else {
          const aVal = a[columnKey] || '';
          const bVal = b[columnKey] || '';
          if (aVal < bVal) return descending ? 1 : -1;
          if (aVal > bVal) return descending ? -1 : 1;
          return 0;
        }
      });
      return filteredData;
    };
    setFilteredAndSortedData(getFilteredAndSortedData());
  }, [
    serviceOrders,
    searchTerm,
    sortProperties,
    unacknowledgedOrders,
    soNumberFilter,
    customerFilter,
    descriptionFilter,
    customerOrderFilter,
    addressFilter,
    selectedDepartments,
  ]);

  // Change sorting logic
  const handleSortChange = (columnKey) => {
    setSortProperties((prev) => {
      if (prev.columnKey === columnKey) {
        return { ...prev, descending: !prev.descending };
      } else {
        if (columnKey === 'pmwaStatus' || columnKey === 'lastUpdate') {
          return { columnKey, descending: true };
        }
        return { columnKey, descending: false };
      }
    });
  };

  const getSortSymbol = (columnKey) => {
    return sortProperties.columnKey === columnKey
      ? sortProperties.descending
        ? '↓'
        : '↑'
      : '';
  };

  // Navigate on row click (excluding the PMWA icon cell)
  const handleRowClick = (order) => {
    navigate(`/service-orders/${order.serviceOrderNumber}/appointments`, {
      state: {
        serviceOrderDetails: {
          ...order,
          CustID: order.CustID || '',
        },
      },
    });
  };

  // Helper function for text truncation
  const truncateText = (text, maxLength) => {
    if (!text) return 'N/A';
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  // For "Bullseye mode," show only last 5 characters (e.g., T0665).
  const truncateCustomerName = (name) => {
    if (!name) return 'N/A';
    return name.length >= 5 ? name.slice(-5) : name;
  };

  // Instead of forcing a sort when showPMWAColumn = false,
  // just return the filtered + sorted data as is:
  const sortedOrders = useMemo(() => {
    return filteredAndSortedData;
  }, [filteredAndSortedData, showPMWAColumn]);

  // NEW useEffect: Load the latest PMWA note for every service order on initial load
  useEffect(() => {
    if (!hasLoadedNotes && serviceOrders && serviceOrders.length > 0) {
      const loadAllLatestNotes = async () => {
        const updatedServiceOrders = await Promise.all(
          serviceOrders.map(async (order) => {
            try {
              const response = await fetch(PMWA_NOTES_ENDPOINT, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'x-api-key': API_KEY,
                },
                body: JSON.stringify({ work_order_number: order.customerOrder }),
              });

              if (!response.ok) {
                console.error(`Error fetching notes for order: ${order.customerOrder}`);
                return { ...order, lastPMWANote: null };
              }

              const data = await response.json();
              if (data && data.length > 0) {
                // Sort descending by create_date to get the most recent note
                const sortedNotes = [...data].sort(
                  (a, b) => new Date(b.create_date) - new Date(a.create_date)
                );
                return { ...order, lastPMWANote: sortedNotes[0] };
              } else {
                return { ...order, lastPMWANote: null };
              }
            } catch (error) {
              console.error(
                `Error fetching notes for order ${order.customerOrder}:`,
                error
              );
              return { ...order, lastPMWANote: null };
            }
          })
        );
        // Update the serviceOrders with the new lastPMWANote value.
        setServiceOrders(updatedServiceOrders);
      };

      loadAllLatestNotes();
      setHasLoadedNotes(true);
    }
  }, [serviceOrders, hasLoadedNotes, API_KEY, PMWA_NOTES_ENDPOINT, setServiceOrders]);

  return (
    <div className="awf-table-wrapper">
      {/* Modal Implementation */}
      {notesModalOpen && (
        <div className="modal-overlay">
          <div
            className="modal-content"
            style={{
              width: '900px',
              maxWidth: '95%',
              display: 'flex',
              flexDirection: 'column',
              height: '80%',
            }}
          >
            <h2 style={{ marginBottom: '1rem' }}>
              Notes for Service Order: {selectedOrder?.serviceOrderNumber || ''} -{' '}
              {selectedOrder?.customerOrder || ''}
            </h2>

            {/* New Note Input Section */}
            <div
              style={{
                marginBottom: '1.5rem',
                padding: '1rem',
                borderBottom: '1px solid #ddd',
                backgroundColor: '#f0f0f0',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <textarea
                value={newNoteContent}
                onChange={(e) => setNewNoteContent(e.target.value)}
                placeholder="Send a new message..."
                style={{
                  flex: 1,
                  resize: 'none',
                  padding: '0.5rem',
                  borderRadius: '4px',
                  border: '1px solid #ccc',
                  marginRight: '0.5rem',
                  fontSize: '1rem',
                  height: '60px',
                }}
              />
              <button
                onClick={handleSendNote}
                disabled={isSending}
                style={{
                  padding: '0.5rem 1rem',
                  borderRadius: '4px',
                  border: 'none',
                  backgroundColor: '#007bff',
                  color: '#fff',
                  cursor: isSending ? 'not-allowed' : 'pointer',
                  fontSize: '1rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {isSending && <FaSpinner className="spinner" style={{ marginRight: '0.5rem' }} />}
                {isSending ? 'Sending...' : 'Send'}
              </button>
            </div>

            {/* Feedback Messages */}
            {sendStatus === 'success' && (
              <div
                style={{
                  marginBottom: '1rem',
                  padding: '0.75rem 1rem',
                  backgroundColor: '#d4edda',
                  color: '#155724',
                  borderRadius: '4px',
                  border: '1px solid #c3e6cb',
                }}
              >
                {hasUnacknowledgedNotes
                  ? 'Note sent successfully.'
                  : 'Notes acknowledged successfully.'}
              </div>
            )}
            {sendStatus === 'error' && (
              <div
                style={{
                  marginBottom: '1rem',
                  padding: '0.75rem 1rem',
                  backgroundColor: '#f8d7da',
                  color: '#721c24',
                  borderRadius: '4px',
                  border: '1px solid #f5c6cb',
                }}
              >
                {sendErrorMessage || 'Failed to send the note.'}
              </div>
            )}

            <div
              style={{
                flex: '1 1 auto',
                overflowY: 'auto',
                marginBottom: '1rem',
              }}
            >
              {loadingNotes ? (
                <p>Loading notes...</p>
              ) : error ? (
                <p className="error-message">{error}</p>
              ) : notes.length > 0 ? (
                notes.map((note) => {
                  const localDateTime = new Date(note.create_date).toLocaleString();
                  const logType = note.log_type ? note.log_type.toLowerCase() : '';
                  const isVendor = logType.includes('vendor');
                  const ackValue = getAcknowledgmentValue(note);
                  const needsPulse = ackValue === null && !isVendor;

                  const backgroundColor = isVendor ? '#e6f7ff' : '#ffe6e6';
                  const cardClassName = `note-card${needsPulse ? ' pulse' : ''}`;

                  return (
                    <div 
                      className={cardClassName}
                      key={note.work_log_id}
                      style={{ backgroundColor }}
                    >
                      {/* Header: icon + log_type + date/time */}
                      <div
                        style={{
                          fontSize: '1.1rem',
                          fontWeight: '600',
                          marginBottom: '0.5rem',
                          color: '#333',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {isVendor ? (
                          <img
                            src="/collinslogo.png"
                            alt="Vendor Logo"
                            style={{
                              width: '24px',
                              height: '24px',
                              marginRight: '0.5rem',
                            }}
                          />
                        ) : (
                          <FaBullseye
                            color="red"
                            style={{
                              marginRight: '0.5rem',
                              width: '24px',
                              height: '24px',
                            }}
                          />
                        )}
                        <span>{note.log_type || 'No Type'}</span>
                        <span
                          style={{
                            fontSize: '0.9rem',
                            color: '#777',
                            marginLeft: '0.5rem',
                          }}
                        >
                          ({localDateTime})
                        </span>
                      </div>

                      {/* Body: created_by and description_long_description */}
                      <div style={{ lineHeight: '1.6', color: '#555' }}>
                        <strong>{note.create_by}:</strong> {parse(DOMPurify.sanitize(note.description_long_description))}
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No notes available.</p>
              )}
            </div>
            <div
              style={{
                flexShrink: 0,
                display: 'flex',
                justifyContent: 'flex-end',
                paddingTop: '0.5rem',
                borderTop: '1px solid #ddd',
              }}
            >
              {/* Reviewed Notes Button */}
              <button
                onClick={handleAcknowledgeNotes}
                disabled={!hasUnacknowledgedNotes || isSending}
                style={{
                  padding: '0.5rem 1rem',
                  borderRadius: '4px',
                  border: 'none',
                  backgroundColor: hasUnacknowledgedNotes ? '#28a745' : '#6c757d',
                  color: '#fff',
                  cursor: hasUnacknowledgedNotes && !isSending ? 'pointer' : 'not-allowed',
                  fontSize: '1rem',
                  marginRight: '0.5rem',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {isSending && <FaSpinner className="spinner" style={{ marginRight: '0.5rem' }} />}
                {isSending ? 'Acknowledging...' : 'Review Notes'}
              </button>

              {/* Close Button */}
              <button onClick={handleCloseNotesModal} className="awf-button">
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Top search row (existing) */}
      <table className="service-order-table">
        <thead>
          <tr>
            <th colSpan={showPMWAColumn ? 8 : 7}>
              <div className="awf-searchContainer">
                <input
                  type="text"
                  placeholder="Search service orders..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="awf-searchInput service-order-search"
                />
              </div>
            </th>
          </tr>

          {/* NEW: Filter Row (above column headers). This row spans all columns except PMWA. */}
          <tr>
            {showPMWAColumn && <th></th>}
            {/* SO# Filter */}
            <th>
              <input
                type="text"
                placeholder="Filter SO#"
                value={soNumberFilter}
                onChange={(e) => setSoNumberFilter(e.target.value)}
                className="column-filter-input"
              />
            </th>
            {/* Customer Filter */}
            <th>
              <input
                type="text"
                placeholder="Filter by Customer"
                value={customerFilter}
                onChange={(e) => setCustomerFilter(e.target.value)}
                className="column-filter-input"
              />
            </th>
            {/* Description Filter */}
            <th>
              <input
                type="text"
                placeholder="Filter by Description"
                value={descriptionFilter}
                onChange={(e) => setDescriptionFilter(e.target.value)}
                className="column-filter-input"
              />
            </th>
            {/* Customer Order Filter */}
            <th>
              <input
                type="text"
                placeholder="Filter Customer Order"
                value={customerOrderFilter}
                onChange={(e) => setCustomerOrderFilter(e.target.value)}
                className="column-filter-input"
              />
            </th>
            {/* Address Filter */}
            <th>
              <input
                type="text"
                placeholder="Filter Address"
                value={addressFilter}
                onChange={(e) => setAddressFilter(e.target.value)}
                className="column-filter-input"
              />
            </th>
            {/* Department Filter (with collapsible checkboxes) */}
            <th>
              <div className="department-filter-container">
                <button
                  type="button"
                  className="btn btn-primary dept-filter-toggle"
                  onClick={toggleDeptFiltersOpen}
                >
                  Department
                  {deptFiltersOpen ? (
                    <FaChevronUp style={{ marginLeft: '0.3rem' }} />
                  ) : (
                    <FaChevronDown style={{ marginLeft: '0.3rem' }} />
                  )}
                </button>
                {deptFiltersOpen && (
                  <div className="dept-filter-panel">
                    <label className="dept-filter-label">
                      <input
                        type="checkbox"
                        onChange={handleSelectAllDepartments}
                        checked={selectedDepartments.length === departmentList.length}
                      />
                      {selectedDepartments.length === departmentList.length
                        ? 'Unselect All'
                        : 'Select All'}
                    </label>
                    {departmentList.map((dept) => (
                      <div key={dept} className="dept-filter-item">
                        <label className="dept-filter-label">
                          <input
                            type="checkbox"
                            value={dept}
                            checked={selectedDepartments.includes(dept)}
                            onChange={() => toggleOneDepartment(dept)}
                          />
                          {dept}
                        </label>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </th>
          </tr>

          {/* The existing header row */}
          <tr>
            {showPMWAColumn && (
              <th>
                <ResizableBox
                  width={columnWidths.pmwaStatus}
                  height={40}
                  axis="x"
                  minConstraints={[60, 40]}
                  maxConstraints={[120, 40]}
                  handle={<span className="react-resizable-handle react-resizable-handle-e" />}
                  onResizeStop={(e, { size }) => handleResize('pmwaStatus', size.width)}
                >
                  <div
                    className="header-cell"
                    onClick={() => handleSortChange('pmwaStatus')}
                    style={{ cursor: 'pointer', userSelect: 'none', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    PMWA {getSortSymbol('pmwaStatus')}
                  </div>
                </ResizableBox>
              </th>
            )}

            {/* NEW: "Last Update" header, only when showPMWAColumn = true */}
            {showPMWAColumn && (
              <th>
                <ResizableBox
                  width={columnWidths.lastUpdate}
                  height={40}
                  axis="x"
                  minConstraints={[120, 40]}
                  maxConstraints={[300, 40]}
                  handle={<span className="react-resizable-handle react-resizable-handle-e" />}
                  onResizeStop={(e, { size }) => handleResize('lastUpdate', size.width)}
                >
                  <div
                    className="header-cell"
                    onClick={() => handleSortChange('lastUpdate')}
                    style={{ cursor: 'pointer', userSelect: 'none', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                  >
                    Last Update {getSortSymbol('lastUpdate')}
                  </div>
                </ResizableBox>
              </th>
            )}

            {/* SO Number Column */}
            <th>
              <ResizableBox
                width={columnWidths.soNumber}
                height={40}
                axis="x"
                minConstraints={[60, 40]}
                maxConstraints={[120, 40]}
                handle={<span className="react-resizable-handle react-resizable-handle-e" />}
                onResizeStop={(e, { size }) => handleResize('soNumber', size.width)}
              >
                <div
                  className="header-cell"
                  onClick={() => handleSortChange('soNumber')}
                  style={{ cursor: 'pointer', userSelect: 'none', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  SO# {getSortSymbol('soNumber')}
                </div>
              </ResizableBox>
            </th>

            {/* Customer Name Column */}
            <th>
              <ResizableBox
                width={columnWidths.customerName}
                height={40}
                axis="x"
                minConstraints={[150, 40]}
                handle={<span className="react-resizable-handle react-resizable-handle-e" />}
                onResizeStop={(e, { size }) => handleResize('customerName', size.width)}
              >
                <div
                  className="header-cell"
                  onClick={() => handleSortChange('customerName')}
                  style={{ cursor: 'pointer', userSelect: 'none', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  Customer {getSortSymbol('customerName')}
                </div>
              </ResizableBox>
            </th>

            {/* Details Column */}
            <th>
              <ResizableBox
                width={columnWidths.details}
                height={40}
                axis="x"
                minConstraints={[150, 40]}
                handle={<span className="react-resizable-handle react-resizable-handle-e" />}
                onResizeStop={(e, { size }) => handleResize('details', size.width)}
              >
                <div
                  className="header-cell"
                  onClick={() => handleSortChange('details')}
                  style={{ cursor: 'pointer', userSelect: 'none', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  Service Order Description {getSortSymbol('details')}
                </div>
              </ResizableBox>
            </th>

            {/* Customer Order */}
            <th>
              <ResizableBox
                width={columnWidths.customerOrder}
                height={40}
                axis="x"
                minConstraints={[100, 40]}
                handle={<span className="react-resizable-handle react-resizable-handle-e" />}
                onResizeStop={(e, { size }) => handleResize('customerOrder', size.width)}
              >
                <div
                  className="header-cell"
                  onClick={() => handleSortChange('customerOrder')}
                  style={{ cursor: 'pointer', userSelect: 'none', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  Customer Order {getSortSymbol('customerOrder')}
                </div>
              </ResizableBox>
            </th>

            {/* Address */}
            <th>
              <ResizableBox
                width={columnWidths.address}
                height={40}
                axis="x"
                minConstraints={[150, 40]}
                handle={<span className="react-resizable-handle react-resizable-handle-e" />}
                onResizeStop={(e, { size }) => handleResize('address', size.width)}
              >
                <div
                  className="header-cell"
                  onClick={() => handleSortChange('address')}
                  style={{ cursor: 'pointer', userSelect: 'none', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  Address {getSortSymbol('address')}
                </div>
              </ResizableBox>
            </th>

            {/* Department */}
            <th>
              <ResizableBox
                width={columnWidths.department}
                height={40}
                axis="x"
                minConstraints={[100, 40]}
                handle={<span className="react-resizable-handle react-resizable-handle-e" />}
                onResizeStop={(e, { size }) => handleResize('department', size.width)}
              >
                <div
                  className="header-cell"
                  onClick={() => handleSortChange('department')}
                  style={{ cursor: 'pointer', userSelect: 'none', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  Department {getSortSymbol('department')}
                </div>
              </ResizableBox>
            </th>
          </tr>
        </thead>

        <tbody>
          {sortedOrders.map((order, idx) => {
            const isUnacknowledged = unacknowledgedOrders.some(
              (unack) => unack.work_order_number === order.customerOrder
            );
            const hasHQ_LVMUpdate = unacknowledgedOrders.some(
              (unack) =>
                unack.work_order_number === order.customerOrder &&
                unack.isHQ_LVMUpdate === true
            );

            // Determine if it's vendor or target; if no notes exists, these fields won't be used.
            const logType = order.lastPMWANote?.log_type?.toLowerCase() || '';
            const isVendor = logType.includes('vendor');

            return (
              <tr
                key={idx}
                className={`service-order-row ${showPMWAColumn ? 'bullseye-highlight' : ''}`}
                onClick={() => handleRowClick(order)}
                style={{
                  backgroundColor: hasHQ_LVMUpdate ? '#ffe6e6' : 'transparent',
                }}
              >
                {showPMWAColumn && (
                  <td
                    className="pmwa-cell"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenNotesModal(order);
                    }}
                  >
                    <FaStickyNote
                      size={18}
                      className="pmwa-icon"
                      color={isUnacknowledged ? 'red' : 'black'}
                    />
                  </td>
                )}

                {showPMWAColumn && (
                  <td
                    className="last-update-cell"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleOpenNotesModal(order);
                    }}
                    style={{
                      backgroundColor: order.lastPMWANote
                        ? isVendor
                          ? '#e6f7ff'
                          : '#ffe6e6'
                        : 'white',
                    }}
                  >
                    {order.lastPMWANote ? (
                      <div className="tooltip-wrapper">
                        {isVendor ? (
                          <img
                            src="/collinslogo.png"
                            alt="Vendor Logo"
                            style={{ width: '20px', marginRight: '0.5rem' }}
                          />
                        ) : (
                          <FaBullseye
                            color="red"
                            style={{ marginRight: '0.5rem', width: '20px', height: '20px' }}
                          />
                        )}
                        {new Date(order.lastPMWANote.create_date).toLocaleDateString()}
                        <div className="tooltip-content">
                          {order.lastPMWANote.description_long_description}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </td>
                )}

                <td className="so-number">{order.serviceOrderNumber || 'N/A'}</td>
                <td className="customer-name" title={order.customerName || 'N/A'}>
                  {showPMWAColumn
                    ? truncateCustomerName(order.customerName)
                    : truncateText(order.customerName, 50)}
                </td>
                <td className="details" title={order.details || 'N/A'}>
                  {truncateText(order.details, 50)}
                </td>
                <td className="customer-order">{order.customerOrder || ''}</td>
                <td className="address">{order.address || 'N/A'}</td>
                <td className="department">{order.department || 'N/A'}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
