// NavLinks.jsx

import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import UserContext from './context/UserContext';

// Import components
import Home from './Home';
import CalendarOnCall from './CalendarOnCall';
import CalendarPTO from './CalendarPTO';
import ITAdmin from './ITAdmin';
import UserAdmin from './UserAdmin';
import UFC from './UFC';
import Training from './Training';
import PhoneList from './PhoneList';
import AppointmentWorkFlow from './AppointmentWorkFlow';
import ServiceOrderLookup from './ServiceOrderLookup';
import AppointmentScreen from './AppointmentScreen';
import ManagePOs from './ManagePOs';
import DispatcherBoard from './DispatcherBoard';
import TechnicianTimeOff from './TechnicianTimeOff';
import ITAdminCustomerAccounts from './ITAdminCustomerAccounts';

export default function NavLinks() {
  const { user } = useContext(UserContext);

  const hasSpecialAccess = false;

  return (
    <div>
      <div className="content-spacing">
        {/* Main Menu */}
        <div className='container sticky no__padding hidden__on__mobile white__background small__padding__top__bottom grey__bottom__border' >
          <div className='row'>
            <div className='col'>
              <Button className='btn-collins border fill__width' as={Link} to="/">Home</Button>
            </div>
            <div className="col">
              <Dropdown>
                <Dropdown.Toggle className='btn-collins border fill__width'>
                  Techroom
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/CalendarOnCall">On Call Calendar</Dropdown.Item>
                  <Dropdown.Item href="https://app.smartsheet.com/b/form/5a919dee44034735a03e9f9c70fcfa33" target="_blank">Request PTO</Dropdown.Item>
                  <Dropdown.Item href="https://www.appsheet.com/start/1e3f4aef-38f8-43d6-becb-70245f81036b" target="_blank">Phone List</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/Training">Training</Dropdown.Item>
                  <Dropdown.Item href="https://app.smartsheet.com/b/form/e7861e6197194093ad2312ef81b317ae" target="_blank">Water Heater</Dropdown.Item>
                  {(user.role_id >= 1) && (
                    <Dropdown.Item as={Link} to="/AppointmentWorkFlow">Appointments</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            {user.role_id >= 2 && (
              <div className='col'>
                <Dropdown>
                  <Dropdown.Toggle className='btn-collins border fill__width'>
                    Operations
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="https://app.smartsheet.com/b/publish?EQBCT=cb06343e7f8842029dc194824813e5cd" target="_blank">PTO Calendar</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/ServiceOrderLookup">Service Orders</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/ManagePOs">Manage POs</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/DispatcherBoard">Dispatcher Board</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/TechnicianTimeOff">Tech Time Off</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}

            {user.role_id >= 9 && (
              <div className='col'>
                <Dropdown>
                  <Dropdown.Toggle className='btn-collins border fill__width'>
                    IT Admin
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item as={Link} to="/UserAdmin">User Admin</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/UFC">UFC</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/customeraccounts">Customer Accounts</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>

        {/* Routes */}
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/CalendarOnCall' element={<CalendarOnCall />} />
          <Route path='/CalendarPTO' element={<CalendarPTO />} />
          <Route path='/PhoneList' element={<PhoneList />} />
          <Route path='/Training' element={<Training />} />
          {(user.role_id >= 1)  && (
            <Route path='/AppointmentWorkFlow' element={<AppointmentWorkFlow />} />
          )}
          <Route
            path='/service-orders/:serviceOrderNumber/appointments'
            element={<AppointmentScreen />}
          />
          {user.role_id >= 2 && (
            <>
              <Route path='/ServiceOrderLookup' element={<ServiceOrderLookup />} />
              <Route path='/ManagePOs' element={<ManagePOs />} />
              <Route path='/DispatcherBoard' element={<DispatcherBoard />} />
              <Route path='/TechnicianTimeOff' element={<TechnicianTimeOff />} />
            </>
          )}
          {user.role_id >= 9 && (
            <>
              <Route path='/UserAdmin' element={<UserAdmin />} />
              <Route path='/UFC' element={<UFC />} />
              <Route path='/customeraccounts' element={<ITAdminCustomerAccounts />} />
            </>
          )}
        </Routes>
      </div>
    </div>
  );
}
