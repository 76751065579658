import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Modal, Button, Spinner } from 'react-bootstrap';

/**
 * AppointmentNotes.jsx
 *
 * Props:
 *  - serviceOrderID: string (e.g. "FS000006"), used to fetch/update notes
 *  - username: string, the username of the current user
 *  - appointment: string/object, representing the current appointment
 *
 * This component fetches two note fields:
 *  1. UsrIntNotes (internal notes)
 *  2. note (invoice notes, but also can contain HTML)
 *
 * Both are displayed as HTML, and both are editable with ReactQuill.
 */

// Simple helper function to strip HTML tags and preserve newlines.
function convertHtmlToPlainText(htmlString) {
  if (!htmlString) return '';
  // Replace <br> with newline
  let result = htmlString.replace(/<br\s*[/]?>/gi, '\n');
  // Remove other HTML tags
  result = result.replace(/<[^>]+>/g, '');
  return result;
}

// NEW HELPER: Generate signature stamp using current date and user's initials
function getSignatureStamp(username) {
  if (!username) return "";
  // Extract the part of the email before "@" and split on dot or underscore
  const parts = username.split('@')[0].split(/[._]/);
  let initials = "";
  if (parts.length >= 2) {
    initials = parts[0].charAt(0) + parts[1].charAt(0);
  } else {
    initials = username.slice(0, 2);
  }
  initials = initials.toUpperCase();

  const now = new Date();
  const month = now.getMonth() + 1; // Month as a number, e.g., 2 for February
  const day = now.getDate();        // Day as a number

  return `-${month}/${day} ${initials}:`;
}

// NEW HELPER: Append signature stamp if one doesn't already exist at the end of the note
function appendSignatureStamp(note, username) {
  const stamp = getSignatureStamp(username);
  // Check if note appears to be HTML content
  const isHTML = /<\/?[a-z][\s\S]*>/i.test(note);
  
  if (isHTML) {
    // For HTML content, use a spacer paragraph before adding the stamp wrapped in its own <p> tag.
    const genericRegex = /<p><br\/?><\/p><p>-\d+\/\d+ [A-Z]{1,2}:\s*<\/p>$/;
    if (genericRegex.test(note)) {
      return note;
    }
    if (note.trim() === "") {
      return `<p>${stamp}</p>`;
    }
    return note + "<p><br/></p>" + `<p>${stamp}</p>`;
  } else {
    // For plain text, use newlines to separate the stamp.
    const genericRegex = /\n\n-\d+\/\d+ [A-Z]{1,2}:$/;
    if (genericRegex.test(note)) {
      return note;
    }
    if (note.trim() === "") {
      return stamp;
    }
    return note + "\n\n" + stamp;
  }
}

const AppointmentNotes = ({ serviceOrderID, username, appointment }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // The fetched data for these notes, as HTML strings
  const [usrIntNotesContent, setUsrIntNotesContent] = useState('');
  const [noteContent, setNoteContent] = useState('');

  // Which field is in "edit" mode, displayed in the modal:
  // 'UsrIntNotes' or 'note' or null
  const [openModalFor, setOpenModalFor] = useState(null);

  // Add a loading state for the save button
  const [saving, setSaving] = useState(false);

  // API key
  const API_KEY = process.env.REACT_APP_CONEXCS_API_KEY;

  // ------------------------------------------------
  //  1) Derive numeric ID vs. FS-based ID
  // ------------------------------------------------
  // For GET, we need "000006"; for UPDATE, we need "FS000006"
  // We'll parse the incoming ID:
  //   If "FS000006" => numeric is "000006"
  //   If "000006" => numeric is "000006" (no prefix)
  //   If there's no "FS" prefix, we assume it's already numeric
  const numericID = serviceOrderID.replace(/^FS/i, '');
  // Pad to 6 digits just in case:
  const numericIDForGet = numericID.padStart(6, '0');
  // The raw serviceOrderID we pass back for updates:
  // e.g. "FS000006" from props
  const fsIDForUpdate = serviceOrderID;

  // -----------------------------------------------
  // 2) Fetch notes from the GET API on mount
  // -----------------------------------------------
  useEffect(() => {
    const fetchNotes = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_get_acu_specific_so',
          {
            method: 'POST',
            headers: {
              'x-api-key': API_KEY,
              'Content-Type': 'application/json',
            },
            // The API requires { serviceOrderID: '000006' } for retrieval
            body: JSON.stringify({ serviceOrderID: numericIDForGet }),
          }
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch. Status: ${response.status}`);
        }

        const jsonData = await response.json();
        // The Lambda typically returns { statusCode, body, ... }
        // The body is a string with array data
        const parsedBody = JSON.parse(jsonData.body);
        if (!Array.isArray(parsedBody) || parsedBody.length < 1) {
          throw new Error('No service order data returned.');
        }

        // We expect the first item to contain additionalFields
        const soData = parsedBody[0];
        // Read "UsrIntNotes" and "note" from additionalFields
        const intNotes = soData?.additionalFields?.UsrIntNotes || '';
        const rawInvoiceNote = soData?.additionalFields?.note || '';
        const invoiceNotePlain = convertHtmlToPlainText(rawInvoiceNote);

        setUsrIntNotesContent(intNotes);
        setNoteContent(invoiceNotePlain);
      } catch (err) {
        console.error(err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (serviceOrderID) {
      fetchNotes();
    }
  }, [serviceOrderID, numericIDForGet, API_KEY]);

  // -----------------------------------------------
  // 3) Modal open/close
  // -----------------------------------------------
  const handleOpenModal = (field) => {
    if (field === 'UsrIntNotes') {
      setUsrIntNotesContent((prev) => appendSignatureStamp(prev, username));
    } else if (field === 'note') {
      setNoteContent((prev) => appendSignatureStamp(prev, username));
    }
    setOpenModalFor(field);
  };

  const handleCloseModal = () => {
    setOpenModalFor(null);
  };

  // -----------------------------------------------
  // 4) Saving updated notes
  // -----------------------------------------------
  const handleSaveModal = async () => {
    if (!openModalFor) return;

    setSaving(true); // Start loading spinner

    try {
      // If editing UsrIntNotes, use usrIntNotesContent; otherwise noteContent
      const isUsrIntNotes = openModalFor === 'UsrIntNotes';
      const updatedNotes = isUsrIntNotes ? usrIntNotesContent : noteContent;
      const updateField = isUsrIntNotes ? 'UsrIntNotes' : 'note';

      const payload = {
        // For updates, the API wants "FS000006"
        serviceOrderNumber: fsIDForUpdate,
        updatedNotes,
        updateField,
        username,      // Added username
        appointment,   // Added appointment
      };

      const response = await fetch(
        'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_update_notes',
        {
          method: 'POST',
          headers: {
            'x-api-key': API_KEY,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        }
      );

      if (!response.ok) {
        throw new Error(`Update failed. Status: ${response.status}`);
      }

      // Optionally read the update response
      const result = await response.json();
      console.log('Update success:', result);

      // Close modal
      handleCloseModal();
    } catch (err) {
      console.error(err);
      alert('Error updating notes: ' + err.message);
    } finally {
      setSaving(false); // Stop loading spinner
    }
  };

  // -----------------------------------------------
  // 5) Render
  // -----------------------------------------------
  if (!serviceOrderID) {
    return <div style={{ margin: 20 }}>No Service Order selected.</div>;
  }

  if (loading) {
    return <div style={{ margin: 20 }}>Loading notes...</div>;
  }

  if (error) {
    return (
      <div style={{ margin: 20, color: 'red' }}>
        Error fetching notes: {error}
      </div>
    );
  }

  return (
    <div style={{ marginTop: 20 }}>
      <h4>Notes for {serviceOrderID}</h4>

      {/* Card #1 - Invoice Note: now stored and displayed as plain text */}
      <div className="card mb-3">
        <div className="card-header" style={{ backgroundColor: '#0066cc', color: 'white' }}>
          <strong>Work Description - Invoice Notes</strong>
        </div>
        <div className="card-body">
          <pre style={{ whiteSpace: 'pre-wrap', margin: 0 }}>
            {noteContent}
          </pre>
          <div className="mt-3">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => handleOpenModal('note')}
            >
              Edit
            </button>
          </div>
        </div>
      </div>

      {/* Card #2: UsrIntNotes (Internal) */}
      <div className="card">
        <div className="card-header" style={{ backgroundColor: '#0066cc', color: 'white' }}>
          <strong>Internal Notes</strong>
        </div>
        <div className="card-body">
          <div
            style={{ maxHeight: '200px', overflowY: 'auto' }}
            dangerouslySetInnerHTML={{ __html: usrIntNotesContent }}
          />
          <div className="mt-3">
            <button
              className="btn btn-primary btn-sm"
              onClick={() => handleOpenModal('UsrIntNotes')}
            >
              Edit
            </button>
          </div>
        </div>
      </div>

      {/* Modal for editing */}
      <Modal show={!!openModalFor} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {openModalFor === 'UsrIntNotes' ? 'Edit Internal Notes' : 'Edit Invoice Notes'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: '70vh', overflowY: 'auto' }}>
          {openModalFor === 'UsrIntNotes' ? (
            // Quill editor for UsrIntNotes
            <ReactQuill
              key="UsrIntNotes"
              value={usrIntNotesContent}
              onChange={setUsrIntNotesContent}
              theme="snow"
              style={{ height: '300px' }}
            />
          ) : (
            // Plain text <textarea> for Invoice Note
            <textarea
              key="invoiceNote"
              style={{ width: '100%', height: '300px' }}
              value={noteContent}
              onChange={(e) => setNoteContent(e.target.value)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveModal} disabled={saving}>
            {saving ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="sr-only">Saving...</span>
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AppointmentNotes;
