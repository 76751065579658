import React, { useEffect, useState } from 'react';
import axios from 'axios';

/**
 * This component provides a CRUD interface for "Customer Accounts" 
 * using two endpoints:
 *   - A POST endpoint to fetch all customer accounts: 
 *     'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/fetch_get_customers'
 *     Note: Although this is used to "GET" customer data, the endpoint expects a POST request;
 *     therefore, an empty payload is sent.
 *   - The fetch_user_management endpoint for create, update, and delete actions.
 *
 * The fetch endpoint returns a response that either directly includes a "users" array
 * or includes a "body" property (which is a JSON string) containing the "users" array.
 * In either case, the keys are mapped to lower-case values for display.
 */
export default function ITAdminCustomerAccounts() {
  // Endpoints
  const API_URL = 'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/fetch_user_management'; // for create, update, delete
  const FETCH_CUSTOMERS_API_URL = 'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/fetch_get_customers'; // for fetching customers

  // Get the API key from the .env file
  const API_KEY = process.env.REACT_APP_CONEXCS_API_KEY;

  // State variables
  const [customerAccounts, setCustomerAccounts] = useState([]);
  const [loading, setLoading] = useState(true);

  // For create / update form usage
  const [editingUser, setEditingUser] = useState(null); // Holds the selected user for updating
  const [newUser, setNewUser] = useState({ username: '', email: '', password: '' });

  // For search functionality
  const [searchTerm, setSearchTerm] = useState('');

  /**
   * Fetch all customer users using the new POST endpoint.
   * If the response contains a "body" property, it is parsed;
   * otherwise, it directly checks for a "users" array.
   */
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          FETCH_CUSTOMERS_API_URL,
          {},
          { headers: { 'x-api-key': API_KEY } }
        );

        let users = [];
        // Check if the response contains a body property; otherwise, assume data is directly available.
        if (response.data) {
          if (response.data.body) {
            const parsedBody = JSON.parse(response.data.body);
            users = parsedBody.users;
          } else if (response.data.users) {
            users = response.data.users;
          }
        }

        if (Array.isArray(users)) {
          // Map the returned keys to the ones used in the UI (camelCase)
          const accounts = users.map((user) => ({
            userId: user.UserID,
            username: user.Username,
            email: user.Email
          }));
          setCustomerAccounts(accounts);
        } else {
          setCustomerAccounts([]);
        }
      } catch (error) {
        console.error('Error fetching customers:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, [API_KEY]);

  /**
   * Create new user
   */
  const createUser = async (e) => {
    e.preventDefault();

    const payload = {
      action: 'create',
      username: newUser.username,
      email: newUser.email,
      password: newUser.password
    };

    try {
      const res = await axios.post(
        API_URL,
        payload,
        { headers: { 'x-api-key': API_KEY } }
      );

      // Update state with the new user.
      setCustomerAccounts(prev => [
        ...prev,
        {
          userId: res.data.userId || Math.random(),
          username: newUser.username,
          email: newUser.email
        }
      ]);

      // Clear form fields
      setNewUser({ username: '', email: '', password: '' });
    } catch (error) {
      console.error('Error creating user:', error);
    }
  };

  /**
   * Delete a user
   */
  const deleteUser = async (userId) => {
    const payload = {
      action: 'delete',
      userId: userId
    };

    try {
      await axios.post(
        API_URL,
        payload,
        { headers: { 'x-api-key': API_KEY } }
      );
      // Remove user from local state
      setCustomerAccounts(prev => prev.filter(user => user.userId !== userId));
    } catch (error) {
      console.error(`Error deleting user (ID: ${userId}):`, error);
    }
  };

  /**
   * Confirm deletion before deleting a user.
   */
  const handleDelete = (userId) => {
    if (window.confirm('Are you sure you want to delete this customer record?')) {
      deleteUser(userId);
    }
  };

  /**
   * Begin editing an existing user
   */
  const startEdit = (user) => {
    setEditingUser({ ...user });
  };

  /**
   * Submit changes for update
   */
  const submitUpdate = async (e) => {
    e.preventDefault();
    if (!editingUser || !editingUser.userId) return;

    const payload = {
      action: 'update',
      userId: editingUser.userId,
    };

    // Only include fields that are non-empty
    if (editingUser.username) payload.username = editingUser.username;
    if (editingUser.email) payload.email = editingUser.email;
    if (editingUser.password) payload.password = editingUser.password;

    try {
      await axios.post(
        API_URL,
        payload,
        { headers: { 'x-api-key': API_KEY } }
      );
      // Update the local list for the updated user
      setCustomerAccounts(prev =>
        prev.map(user =>
          user.userId === editingUser.userId ? editingUser : user
        )
      );
      setEditingUser(null);
    } catch (error) {
      console.error('Error updating user:', error);
    }
  };

  /**
   * Filter accounts based on search criteria (username or email)
   */
  const filteredAccounts = customerAccounts.filter((user) => {
    const searchVal = searchTerm.toLowerCase();
    return (
      (user.username && user.username.toLowerCase().includes(searchVal)) ||
      (user.email && user.email.toLowerCase().includes(searchVal))
    );
  });

  // Render loading indicator if data is still being fetched
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container p-3">
      <h3>Customer Accounts</h3>
      <hr />

      {/* Search Box */}
      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by username or email..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>

      {/* Create New User Form */}
      <div className="card p-3 mb-4">
        <h5>Create New Account</h5>
        <form onSubmit={createUser}>
          <div className="row g-2 mb-3">
            <div className="col">
              <label>Username</label>
              <input
                type="text"
                className="form-control"
                required
                value={newUser.username}
                onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
              />
            </div>
            <div className="col">
              <label>Email</label>
              <input
                type="email"
                className="form-control"
                required
                value={newUser.email}
                onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
              />
            </div>
            <div className="col">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                required
                value={newUser.password}
                onChange={(e) => setNewUser({ ...newUser, password: e.target.value })}
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">Create</button>
        </form>
      </div>

      {/* Accounts List */}
      <table className="table table-striped">
        <thead>
          <tr>
            <th>UserID</th>
            <th>Username</th>
            <th>Email</th>
            <th className="text-end">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredAccounts.map((user) => (
            <tr key={user.userId}>
              <td>{user.userId}</td>
              <td>
                {editingUser && editingUser.userId === user.userId ? (
                  <input
                    type="text"
                    value={editingUser.username || ''}
                    className="form-control"
                    onChange={(e) =>
                      setEditingUser({ ...editingUser, username: e.target.value })
                    }
                  />
                ) : (
                  user.username
                )}
              </td>
              <td>
                {editingUser && editingUser.userId === user.userId ? (
                  <input
                    type="email"
                    value={editingUser.email || ''}
                    className="form-control"
                    onChange={(e) =>
                      setEditingUser({ ...editingUser, email: e.target.value })
                    }
                  />
                ) : (
                  user.email
                )}
              </td>
              <td className="text-end">
                {editingUser && editingUser.userId === user.userId ? (
                  <>
                    <input
                      type="password"
                      placeholder="New Password"
                      className="form-control mb-2"
                      onChange={(e) =>
                        setEditingUser({ ...editingUser, password: e.target.value })
                      }
                    />
                    <button className="btn btn-sm btn-success me-2" onClick={submitUpdate}>
                      Save
                    </button>
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={() => setEditingUser(null)}
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="btn btn-sm btn-primary me-2"
                      onClick={() => startEdit(user)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleDelete(user.userId)}
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
          {filteredAccounts.length === 0 && (
            <tr>
              <td colSpan="4" className="text-center">
                No results found.
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}