import React, { useEffect, useState, useMemo } from 'react';
import { Form, Button, Table, Spinner, Alert } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { fetchAndMatchTechnicians } from '../utils/fetchTechnicians';

/**
 * A component for managing technician time off.
 * Uses the conexcs_timeoff endpoint to fetch, add, update, and delete records.
 */
export default function TechnicianTimeOff() {
  const API_KEY = process.env.REACT_APP_CONEXCS_API_KEY;
  const TIMEOFF_ENDPOINT =
    'https://0urz14ctph.execute-api.us-east-2.amazonaws.com/default/conexcs_timeoff';

  const [timeOffData, setTimeOffData] = useState([]);
  const [technicians, setTechnicians] = useState([]);

  // Loading & error states
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // States for the "Add New" form
  const [selectedTechForAdd, setSelectedTechForAdd] = useState('');
  const [selectedAddTechObj, setSelectedAddTechObj] = useState([]);
  const [addStartDate, setAddStartDate] = useState('');
  const [addEndDate, setAddEndDate] = useState('');

  // States for editing an existing record
  const [editRecordId, setEditRecordId] = useState(null);
  const [selectedTechForEdit, setSelectedTechForEdit] = useState('');
  const [selectedEditTechObj, setSelectedEditTechObj] = useState([]);
  const [editStartDate, setEditStartDate] = useState('');
  const [editEndDate, setEditEndDate] = useState('');

  // Track the logged-in user (hard-coded for demo; replace as needed)
  const currentUser = 'jdoe';

  // For sorting:
  const [sortColumn, setSortColumn] = useState('startdate');
  const [sortDirection, setSortDirection] = useState('asc');

  /**
   * Fetch existing Time Off records from the endpoint (current/upcoming).
   * We simply pass an empty object {} to get all records with enddate >= today.
   */
  const fetchTimeOffRecords = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(TIMEOFF_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify({}), // Empty payload returns current/upcoming
      });

      if (!response.ok) {
        const errText = await response.text();
        throw new Error(`Error: ${response.status} - ${errText}`);
      }

      const data = await response.json();
      setTimeOffData(data);
    } catch (err) {
      console.error('Error fetching time off records:', err);
      setError('Failed to fetch time off records. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Add a new Time Off record.
   */
  const handleAddTimeOff = async () => {
    // Basic validation
    if (!selectedTechForAdd || !addStartDate || !addEndDate) {
      alert('Please select a technician and provide start/end dates.');
      return;
    }

    setLoading(true);
    setError(null);

    // For creation/updated timestamps, we can attach the current time
    const nowISO = new Date().toISOString();

    const payload = {
      action: 'add',
      user_id: parseInt(selectedTechForAdd, 10),
      startdate: addStartDate,
      enddate: addEndDate,
      created_by: currentUser,
      created_date: nowISO,
      updated_by: currentUser,
      updated_date: nowISO
    };

    try {
      const response = await fetch(TIMEOFF_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errText = await response.text();
        throw new Error(`Error: ${response.status} - ${errText}`);
      }

      // After successful add, refetch the data
      await fetchTimeOffRecords();

      // Clear inputs
      setSelectedTechForAdd('');
      setSelectedAddTechObj([]);
      setAddStartDate('');
      setAddEndDate('');
    } catch (err) {
      console.error('Error adding time off:', err);
      setError('Failed to add time off. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Set up the edit form for a chosen record.
   */
  const startEditing = (record) => {
    setEditRecordId(record.TechTimeOffID);
    setSelectedTechForEdit(String(record.user_id));
    // Convert to YYYY-MM-DD for <input type="date" />
    setEditStartDate(record.startdate.split('T')[0]);
    setEditEndDate(record.enddate.split('T')[0]);

    // Set the Typeahead's value to the matching tech object (so it displays properly)
    const matchedTech = technicians.find((t) => t.user_id === record.user_id);
    setSelectedEditTechObj(matchedTech ? [matchedTech] : []);
  };

  /**
   * Update an existing Time Off record.
   */
  const handleUpdateTimeOff = async () => {
    if (!editRecordId || !selectedTechForEdit || !editStartDate || !editEndDate) {
      alert('Please fill out all fields');
      return;
    }

    setLoading(true);
    setError(null);

    const nowISO = new Date().toISOString();

    const payload = {
      action: 'update',
      TechTimeOffID: editRecordId,
      user_id: parseInt(selectedTechForEdit, 10),
      startdate: editStartDate,
      enddate: editEndDate,
      updated_by: currentUser,
      updated_date: nowISO
    };

    try {
      const response = await fetch(TIMEOFF_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errText = await response.text();
        throw new Error(`Error: ${response.status} - ${errText}`);
      }

      // Refresh data and reset edit states
      await fetchTimeOffRecords();
      setEditRecordId(null);
      setSelectedTechForEdit('');
      setSelectedEditTechObj([]);
      setEditStartDate('');
      setEditEndDate('');
    } catch (err) {
      console.error('Error updating time off:', err);
      setError('Failed to update time off. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Delete a Time Off record by ID.
   */
  const handleDeleteTimeOff = async (recordId) => {
    if (!window.confirm('Are you sure you want to delete this record?')) return;

    setLoading(true);
    setError(null);

    const payload = {
      action: 'delete',
      TechTimeOffID: recordId
    };

    try {
      const response = await fetch(TIMEOFF_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': API_KEY,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errText = await response.text();
        throw new Error(`Error: ${response.status} - ${errText}`);
      }

      await fetchTimeOffRecords();
    } catch (err) {
      console.error('Error deleting time off:', err);
      setError('Failed to delete time off. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  /**
   * Sorting logic
   */
  const handleSort = (column) => {
    if (sortColumn === column) {
      // Toggle direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  // Sort the records whenever data or sort state changes
  const sortedTimeOffData = useMemo(() => {
    const sorted = [...timeOffData].sort((a, b) => {
      let valA;
      let valB;

      if (sortColumn === 'TechTimeOffID') {
        valA = a.TechTimeOffID;
        valB = b.TechTimeOffID;
      } else if (sortColumn === 'user_name') {
        valA = a.user_name || '';
        valB = b.user_name || '';
      } else if (sortColumn === 'startdate') {
        valA = a.startdate;
        valB = b.startdate;
      } else if (sortColumn === 'enddate') {
        valA = a.enddate;
        valB = b.enddate;
      } else {
        // Default fallback to ID
        valA = a.TechTimeOffID;
        valB = b.TechTimeOffID;
      }

      // Convert string dates to Date objects (if start/end)
      if (sortColumn === 'startdate' || sortColumn === 'enddate') {
        valA = new Date(valA).getTime();
        valB = new Date(valB).getTime();
      }

      if (valA < valB) return sortDirection === 'asc' ? -1 : 1;
      if (valA > valB) return sortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    return sorted;
  }, [timeOffData, sortColumn, sortDirection]);

  /**
   * Initial load: fetch technicians and existing time-off records.
   */
  useEffect(() => {
    // Get the technician data
    const loadTechnicians = async () => {
      try {
        const techs = await fetchAndMatchTechnicians();
        setTechnicians(techs);
      } catch (err) {
        console.error('Error fetching technicians:', err);
        // We can choose to not show an error here, or do setError(...)
      }
    };

    loadTechnicians();
    fetchTimeOffRecords();
  }, []);

  const now = new Date();

  return (
    <div className="container mt-3">
      <h2 className="mb-3">Technician Time Off Management</h2>

      {error && (
        <Alert variant="danger" className="mb-3">
          <strong>{error}</strong>
        </Alert>
      )}

      {loading && (
        <div className="d-flex align-items-center mb-3">
          <strong className="me-2">Loading...</strong>
          <Spinner animation="border" role="status" />
        </div>
      )}

      {/* --- Add New Time Off Section --- */}
      <div className="card mb-4">
        <div className="card-header">Add Time Off</div>
        <div className="card-body">
          <Form>
            <Form.Group className="mb-3" controlId="selectedTechForAdd">
              <Form.Label>Select Technician</Form.Label>
              <Typeahead
                id="add-tech-typeahead"
                labelKey="employeeName"
                options={technicians}
                placeholder="Type or select a technician..."
                selected={selectedAddTechObj}
                onChange={(selected) => {
                  setSelectedAddTechObj(selected);
                  if (selected && selected.length > 0) {
                    setSelectedTechForAdd(String(selected[0].user_id));
                  } else {
                    setSelectedTechForAdd('');
                  }
                }}
              />
            </Form.Group>

            <div className="row">
              <Form.Group className="mb-3 col-md-6" controlId="addStartDate">
                <Form.Label>Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={addStartDate}
                  onChange={(e) => setAddStartDate(e.target.value)}
                />
              </Form.Group>

              <Form.Group className="mb-3 col-md-6" controlId="addEndDate">
                <Form.Label>End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={addEndDate}
                  onChange={(e) => setAddEndDate(e.target.value)}
                />
              </Form.Group>
            </div>

            <Button variant="primary" onClick={handleAddTimeOff}>
              Add
            </Button>
          </Form>
        </div>
      </div>

      {/* --- Time Off Table --- */}
      <Table bordered hover className="table-responsive">
        <thead>
          <tr style={{ backgroundColor: '#f0f0f0' }}>
            <th
              style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
              onClick={() => handleSort('TechTimeOffID')}
            >
              ID
            </th>
            <th
              style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
              onClick={() => handleSort('user_name')}
            >
              Tech Name
            </th>
            <th
              style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
              onClick={() => handleSort('startdate')}
            >
              Start Date
            </th>
            <th
              style={{ cursor: 'pointer', whiteSpace: 'nowrap' }}
              onClick={() => handleSort('enddate')}
            >
              End Date
            </th>
            <th style={{ whiteSpace: 'nowrap' }}>Actions</th>
          </tr>
        </thead>
        <tbody>
          {sortedTimeOffData.map((record) => {
            // Determine if row should be pastel red (#fdcccc)
            const start = new Date(record.startdate);
            const end = new Date(record.enddate);
            const isCurrentlyOff = now >= start && now <= end;

            const isEditing = editRecordId === record.TechTimeOffID;

            return (
              <tr
                key={record.TechTimeOffID}
                style={isCurrentlyOff ? { backgroundColor: '#fdcccc' } : {}}
              >
                <td>{record.TechTimeOffID}</td>
                <td>
                  {isEditing ? (
                    <Typeahead
                      id={`edit-tech-${record.TechTimeOffID}`}
                      labelKey="employeeName"
                      options={technicians}
                      placeholder="Type or select a technician..."
                      selected={selectedEditTechObj}
                      onChange={(selected) => {
                        setSelectedEditTechObj(selected);
                        if (selected && selected.length > 0) {
                          setSelectedTechForEdit(String(selected[0].user_id));
                        } else {
                          setSelectedTechForEdit('');
                        }
                      }}
                    />
                  ) : (
                    record.user_name
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="date"
                      value={editStartDate}
                      onChange={(e) => setEditStartDate(e.target.value)}
                      style={{ width: '150px' }}
                    />
                  ) : (
                    record.startdate.split('T')[0]
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <Form.Control
                      type="date"
                      value={editEndDate}
                      onChange={(e) => setEditEndDate(e.target.value)}
                      style={{ width: '150px' }}
                    />
                  ) : (
                    record.enddate.split('T')[0]
                  )}
                </td>
                <td>
                  {isEditing ? (
                    <>
                      <Button
                        variant="success"
                        size="sm"
                        className="me-2"
                        onClick={handleUpdateTimeOff}
                      >
                        Save
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={() => {
                          setEditRecordId(null);
                          setSelectedTechForEdit('');
                          setSelectedEditTechObj([]);
                          setEditStartDate('');
                          setEditEndDate('');
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="primary"
                        size="sm"
                        className="me-2"
                        onClick={() => startEditing(record)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDeleteTimeOff(record.TechTimeOffID)}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}